import { AxiosError } from 'axios';
import React from 'react';

import { THttpResponse, useReactQuery } from '@people/ui/api';
import { userRolesError } from '@people/ui/auth/constants';
import { TAuthUserRole } from '@people/ui/auth/interfaces';

/**
 * @public
 */
type TUserRolesResponse = {
  user_id: string;
  role_id: string;
  role: TAuthUserRole;
};

/**
 * @public
 */

export type TuseUserRolesReturn = {
  userRoles: TAuthUserRole[] | undefined;
  isFetchUserRolesError: boolean;
  fetchUserRolesError: Error | null;
  refetchUserRoles: Function;
  fetchUserRolesStatus: 'error' | 'success' | 'pending';
  isFetchingRolesUser: boolean;
};

/**
 * @public
 */
export const useUserRoles = (id: string | undefined | null): TuseUserRolesReturn => {
  const [isFetchUserRolesError, setIsFetchUserRolesError] = React.useState<boolean>(false);
  const [fetchUserRolesError, setFetchUserRolesError] = React.useState<Error | null>(null);

  const { data, error, status, isLoading, refetch, isError, isRefetching, isFetching } =
    useReactQuery<THttpResponse<TUserRolesResponse>>({
      url: `/users/${id}/roles`,
      options: { queryKey: ['user_roles', `${id}`] }
    });

  const roles: TAuthUserRole[] | undefined = React.useMemo(() => {
    if (
      data !== undefined &&
      !(data instanceof AxiosError) &&
      !isLoading &&
      error === null &&
      !isRefetching &&
      !isError
    ) {
      if (Object.prototype.hasOwnProperty.call(data, 'data')) {
        if (Object.keys(data.data).length > 0) {
          const roleData = data.data as TUserRolesResponse[];
          if (roleData.length > 0) {
            return roleData.map((role) => {
              return { name: role.role.name, id: role.role.id };
            });
          }
        } else {
          return undefined;
        }
      } else {
        return undefined;
      }
    }
    return undefined;
  }, [data, error, isError, isLoading, isRefetching]);

  React.useEffect(() => {
    if (isError || error !== null || data instanceof Error || data instanceof AxiosError) {
      setIsFetchUserRolesError(true);
      const fetchUserUserError = new Error(`${userRolesError}`);
      setFetchUserRolesError(fetchUserUserError);
    }
  }, [isError, error, data]);

  return {
    userRoles: roles,
    fetchUserRolesError,
    isFetchUserRolesError,
    refetchUserRoles: refetch,
    fetchUserRolesStatus: status,
    isFetchingRolesUser: isFetching
  };
};
