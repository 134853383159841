import React from 'react';

import { Button, Flex, Text } from '@chakra-ui/react';

type TAddExpenseButtonProps = {
  bg: string;
  label: string;
  color?: string;
  bgHover: string;
  onClick?: () => void;
  isDisabled?: boolean;
  isLoading?: boolean;
  icon: React.ReactNode;
};

export const ExpenseButton: React.FC<TAddExpenseButtonProps> = ({
  bg,
  bgHover,
  color,
  icon,
  isDisabled,
  isLoading,
  label,
  onClick
}) => {
  return (
    <Flex
      h={8}
      gap={1}
      bg={bg}
      as={Button}
      fontSize="sm"
      color={color}
      onClick={onClick}
      paddingInline={2}
      borderRadius="md"
      alignItems="center"
      justifyContent="center"
      isDisabled={isDisabled}
      isLoading={isLoading}
      _disabled={{ opacity: 0.5, cursor: 'not-allowed' }}
      _hover={{ bg: bgHover }}>
      {icon}
      <Text fontSize="sm">{label}</Text>
    </Flex>
  );
};
