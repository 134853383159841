import { useMemo } from 'react';

import { useAuth } from '@people/ui/auth';
import { CLAIM_APPROVAL_PERMISSION_CODENAME } from '@people/ui/claims';

type TUseUserIsClaimApproverReturn = {
  canApproveClaim: boolean;
};

export const useUserIsClaimApprover = (): TUseUserIsClaimApproverReturn => {
  const { userPermissions } = useAuth();

  const canApproveClaim = useMemo(() => {
    if (Array.isArray(userPermissions)) {
      return userPermissions.some(
        (userPermission) => userPermission.codename === CLAIM_APPROVAL_PERMISSION_CODENAME
      );
    }
    return false;
  }, [userPermissions]);

  return {
    canApproveClaim
  };
};
