import createCache from '@emotion/cache';

import type { PaletteMode } from '@mui/material';
import { THEME_ID, createTheme } from '@mui/material/styles';

export const MUI_THEME_IDENTIFIER = THEME_ID;

export const getMuiTheme = (colorMode: PaletteMode) => {
  return createTheme({
    palette: {
      mode: colorMode
    },
    typography: {
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"'
      ].join(',')
    },
    components: {
      MuiButton: {
        defaultProps: {
          sx: {
            minWidth: 'auto',
            textTransform: 'none',
            fontWeight: 'bold'
          }
        }
      }
    }
  });
};

export const muiCache = createCache({
  key: 'mui',
  prepend: true
});

/**
 * @public
 */

export const muiTheme = createTheme({
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(',')
  }
});
