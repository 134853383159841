import React from 'react';

import { Flex, Heading, Text } from '@chakra-ui/react';

type TNumberStatProps = {
  label: string;
  value: string | number;
  valueColor: string;
};

export const NumberStat: React.FC<TNumberStatProps> = ({ label, value, valueColor }) => {
  return (
    <Flex gap={1} flex={1} flexDirection="column" alignItems="center" justifyContent="center">
      <Heading fontSize="sm" fontWeight="semibold">
        {label}
      </Heading>
      <Text fontSize="md" letterSpacing="-0.03em" fontWeight="normal" color={valueColor}>
        {value}
      </Text>
    </Flex>
  );
};
