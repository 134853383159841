export enum ViewUIType {
  HR = 'hr',
  USER = 'user',
  ADMIN = 'admin',
  SUPERVISOR = 'supervisor'
}

export enum ApprovalLevel {
  SUPERVISOR = 'SUPERVISOR',
  ACCOUNTS = 'ACCOUNTS'
}

export enum ClaimStatus {
  PAID = 'PAID',
  DRAFT = 'DRAFT',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING'
}

export enum ClaimApprovalLevel {
  SUPERVISOR_APPROVAL = 'SUPERVISOR APPROVAL',
  ACCOUNTS_APPROVAL = 'ACCOUNTS APPROVAL',
  ACCOUNTS_LEVEL_ONE_APPROVAL = 'ACCOUNTS LEVEL ONE APPROVAL',
  ACCOUNTS_LEVEL_TWO_APPROVAL = 'ACCOUNTS LEVEL TWO APPROVAL'
}

export enum ClaimApprovalLevelStatus {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING'
}
