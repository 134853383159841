import { lazy } from 'react';
import { Navigate, RouteObject, createBrowserRouter } from 'react-router-dom';

import { claimsRoutingObject } from '@people/ui/claims';
import { AppPath } from '@people/ui/shared';

const Login = lazy(() => import('@people/ui/auth/pages/Login'));

const MainDashboard = lazy(() => import('@people/ui/dashboard/pages/MainDashboard'));
// const NotFound = React.lazy(() => import('@people/ui/shared/pages/NotFound'));

const routingObject: RouteObject[] = [
  { path: AppPath.ROOT, element: <Navigate to={AppPath.HOME} /> },
  { path: AppPath.LOGIN, element: <Login /> },
  { path: AppPath.HOME, element: <MainDashboard /> },
  ...claimsRoutingObject
];

export const routing = createBrowserRouter(routingObject);
