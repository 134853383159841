import React from 'react';

import { Flex } from '@chakra-ui/react';

import { ExpenseDataTable } from '@people/ui/claims/components';
import { useExpenseTableColumns } from '@people/ui/claims/hooks';
import { TClaim } from '@people/ui/claims/interfaces';

type TExpenseTableProps = {
  data: TClaim[];
  isLoading: boolean;
};

export const ExpenseTable: React.FC<TExpenseTableProps> = ({ isLoading, data }) => {
  const { columns } = useExpenseTableColumns();

  return (
    <Flex flexDirection="column" flexGrow={1}>
      <Flex px={4} flexShrink={0}></Flex>
      <Flex flexGrow={1} flexDirection="column">
        <ExpenseDataTable isLoading={isLoading} data={data} columns={columns} />
      </Flex>
    </Flex>
  );
};
