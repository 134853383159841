import { FileType } from '@wiocc-systems/wiocc-react-utils';
import { isEmpty, upperFirst, words } from 'lodash';
import moment from 'moment';

import { TEncodedFileAttachment, TFileResponse } from '@people/ui/shared/interfaces';

/**
 * @public
 */

export const titleText = (text: string | undefined) => {
  if (text === undefined || text.replace(/\s/g, '') === '') {
    return text;
  }
  return words(text.replace(/_/g, ' '))
    .map((word) => upperFirst(word))
    .join(' ');
};

/**
 * @public
 */

export const isBrowser = () => {
  return typeof window !== 'undefined';
};

/**
 * @public
 */

export const tryJSONparse = (obj: any) => {
  try {
    return JSON.parse(obj);
  } catch {
    return obj;
  }
};

/**
 * @public
 */

export const tryJSONStringify = (obj: any) => {
  if (typeof obj === 'string') return obj;
  try {
    return JSON.stringify(obj);
  } catch {
    return obj;
  }
};

/**
 * @public
 */

export const formatDateTime = (date: string) => {
  const clean = moment(date, 'DD/MM/YYYY hh:mm:ss').format('MM/DD/YYYY hh:mm:ss');
  const localeDate = new Date(clean + ' UTC').toLocaleString();
  return localeDate;
};

/**
 * @public
 */

export function isEmptyString(x: any) {
  return (
    x === undefined ||
    x === null ||
    (x as string).trim() === 'undefined' ||
    (x as string).trim() === 'null' ||
    isEmpty((x as string).trim())
  );
}

/**
 * @public
 * @function generateUserInitials
 * @param name
 * @returns
 */

export const generateUserInitials = (name: string): string => {
  const words = name.split(' ');
  const initials = [];
  for (let i = 0; i < Math.min(words.length, 2); i++) {
    initials.push(words[i][0].toUpperCase());
  }
  return initials.join('');
};

type Falsy = 0 | '' | false | null | undefined;

export function truthy<T>(x: T): x is Exclude<T, Falsy> {
  return Boolean(x);
}

export function falsy<T>(x: T): x is T & Falsy {
  return Boolean(x);
}

export const isEqualStrings = (firstString?: string, secondString?: string) => {
  if (firstString === undefined || secondString === undefined) {
    return false;
  }
  return firstString.trim().toLowerCase() === secondString.trim().toLowerCase();
};

export const PDF_TYPE = 'application/pdf';

export const ALLOWED_FILE_TYPES = ['image/jpeg', 'image/png', PDF_TYPE];
export const FILE_TYPE_VALIDATION_ERROR =
  'Only the following attachment formats are accepted: .jpeg, .jpg, .pdf';

export const VALID_FILE_EXTENSIONS = {
  '.pdf': FileType.PDF,
  '.jpg': FileType.IMAGE_JPEG,
  '.jpeg': FileType.IMAGE_JPEG,
  '.png': FileType.IMAGE_PNG
};

export const getFullBase64Str = (data: TFileResponse) => {
  if (data === undefined || data?.name === '') {
    return '';
  }
  const extension = data.name.split('.').at(-1) ?? '';

  if (extension === undefined || extension === '') {
    return '';
  }

  const fullExtension = `.${extension}` as keyof typeof VALID_FILE_EXTENSIONS;
  const fileType = VALID_FILE_EXTENSIONS[fullExtension];

  if (data.file.startsWith(`data:${fileType}`)) {
    return data.file;
  }

  return `data:${fileType};base64,${data.file}`;
};

// export const getFileObjectFromBase64 = (base64: string) => {
//   return fetch(base64);
// };

// const notBase64 = /[^A-Z0-9+\/=]/i;
const urlSafeBase64 = /^[A-Z0-9_-]*$/i;

// const defaultBase64Options = {
// urlSafe: false
// };

export function isBase64(str: string) {
  // const len = str.length;

  // if (options.urlSafe) {
  return urlSafeBase64.test(str);
  // }

  // if (len % 4 !== 0 || notBase64.test(str)) {
  //   return false;
  // }

  // const firstPaddingChar = str.indexOf('=');
  // return firstPaddingChar === -1 ||
  //   firstPaddingChar === len - 1 ||
  //   (firstPaddingChar === len - 2 && str[len - 1] === '=');
}

export const getFileObjectFromEncodedAttachment = (arr: TEncodedFileAttachment[]) => {
  return arr.map(async (el) => {
    const base64String = getFullBase64Str(el);
    // if (el === undefined || el?.name === '') {
    //   return [];
    // }
    const extension = el.name.split('.').at(1) ?? '';
    // if (extension === undefined || extension === '') {
    //   return [];
    // }
    const fullExtension = `.${extension}` as keyof typeof VALID_FILE_EXTENSIONS;
    const fileType = VALID_FILE_EXTENSIONS[fullExtension];

    const base64Response = await fetch(base64String);
    const newFile = await base64Response.blob();
    const file = new File([newFile], el.name, { type: fileType });
    console.log('newFile', file);

    return file;
  });
};

export const userInitials = (name?: string | null) => {
  if (!name) {
    return 'JD';
  }
  return name.split(' ')[0].charAt(0).toUpperCase() + name.split(' ')[1].charAt(0).toUpperCase();
};

export function filterByPropertyValue(array: any, key: string, value: any) {
  return array.filter((obj: any) => obj[key] === value);
}

export function convertFloatStringToFloatWithDecimals(value: string | number): number {
  console.log('value', { value });
  console.log('value', { value: typeof value });

  // Ensure the value is a string before performing string operations
  const stringValue = typeof value === 'number' ? value.toString() : value;

  // Remove commas and parse as a float
  let number = parseFloat(stringValue.replace(/,/g, ''));

  // Return 0 if the parsed number is not a valid number
  if (isNaN(number)) return 0;

  // Determine the number of decimal places in the input
  const decimalPlaces = stringValue.includes('.') ? stringValue.split('.')[1].length : 0;

  // Format to the specified decimal places
  number = Number(number.toFixed(decimalPlaces));
  return number;
}

export function convertStringFloatToStringFloatWithDecimals(value: string) {
  let [integerPart, decimalPart] = value.split('.');

  let stringValue = integerPart.replace(/,/g, '');

  let decimalPlaces = 0;

  if (decimalPart !== undefined) {
    decimalPart = decimalPart.replace(/0+$/, '');
    decimalPlaces = decimalPart.length;
    stringValue = `${integerPart}.${decimalPart}`;
  }

  let number = parseFloat(stringValue);

  // Return NaN if the parsed number is not a valid number
  if (isNaN(number)) return 0;

  // Use Intl.NumberFormat to format the number with commas and decimal places
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces
  });

  return formatter.format(number);
}

export function convertFloatToStringFloatWithCommas(value: number) {
  if (isNaN(value)) return ''; // Return an empty string if the input is not a valid number

  const decimalPlaces = value.toString().includes('.') ? value.toString().split('.')[1].length : 0;

  // Use Intl.NumberFormat to format the number with commas and decimal places
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces
  });

  return formatter.format(value);
}
