import { useMemo } from 'react';

import { ADMIN_PERMISSION_CODENAME, HR_PERMISSION_CODENAME } from '../constants';
import { useAuth } from './useAuth';

type TUseIsAuthorisedUserReturn = {
  isSystemAdmin: boolean;
  isHumanResource: boolean;
};

export const useIsAuthorisedUser = (): TUseIsAuthorisedUserReturn => {
  const { userPermissions } = useAuth();

  const isSystemAdmin = useMemo(() => {
    if (Array.isArray(userPermissions)) {
      return userPermissions.some(
        (userPermission) => userPermission.codename === ADMIN_PERMISSION_CODENAME
      );
    }
    return false;
  }, [userPermissions]);

  const isHumanResource = useMemo(() => {
    if (Array.isArray(userPermissions)) {
      return userPermissions.some(
        (userPermission) => userPermission.codename === HR_PERMISSION_CODENAME
      );
    }
    return false;
  }, [userPermissions]);

  return {
    isSystemAdmin,
    isHumanResource
  };
};
