import { getEncodedAttachments } from '@wiocc-systems/wiocc-react-utils';

import { TAuthUser } from '../auth';
import {
  type TEncodedFileAttachment,
  convertFloatStringToFloatWithDecimals,
  convertFloatToStringFloatWithCommas,
  getFullBase64Str
} from '../shared';
import {
  TClaim,
  TClaimFormData,
  TClaimItem,
  TClaimItemFormData,
  TClaimItemReceipt,
  TClaimPayload
} from './interfaces';

/**
 * Converts an object with a base64 file string and name into a File object.
 * @param base64File - An object containing the base64 string and file name.
 * @returns A File object
 */
function base64ToFile(base64File: TEncodedFileAttachment): File {
  const base64 = getFullBase64Str(base64File);
  console.log({ base64File, base64 });

  // const byteString = atob(base64File.file.split(',')[1]); // Decode the base64 string
  // const mimeString = base64File.file.split(',')[0].split(':')[1].split(';')[0]; // Extract MIME type

  const byteString = atob(base64.split(',')[1]); // Decode the base64 string
  const mimeString = base64.split(',')[0].split(':')[1].split(';')[0]; // Extract MIME type

  // Create a typed array from the byteString (binary data)
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  const blob = new Blob([ab], { type: mimeString }); // Create a Blob object from the array buffer
  return new File([blob], base64File.name, { type: mimeString }); // Create the File object
}

/**
 * Converts an array of TClaimItemReceipt objects into File objects or processes encoded receipts.
 * @param receipts - An array of receipts which could be File or TEncodedFileAttachment objects.
 * @returns A Promise resolving to an array of File objects or encoded file attachments.
 */
export const modelFileObjectReceipts = async (receipts: (File | TClaimItemReceipt)[]) => {
  const files: File[] = [];

  receipts.forEach((receipt) => {
    if (getIsTEncodedFileAttachment(receipt)) {
      // Convert Base64 to File object
      files.push(base64ToFile(receipt as TEncodedFileAttachment));
    } else if (receipt instanceof File) {
      files.push(receipt);
    }
  });

  return files;
};

/**
 * Type guard to check if a receipt is a Base64 object (TClaimItemReceipt).
 */
export function isBase64Object(receipt: any): receipt is TClaimItemReceipt {
  return typeof receipt.file === 'string' && typeof receipt.name === 'string';
}

/**
 * @public
 */

export const getIsTEncodedFileAttachment = (obj: any): obj is TEncodedFileAttachment => {
  return typeof obj === 'object' && typeof obj.file === 'string' && typeof obj.name === 'string';
};

/**
 * @public
 */
export const modelReceipts = async (receipts: TClaimItemReceipt[]) => {
  const files: File[] = [];
  const newReceipts: TEncodedFileAttachment[] = [];
  receipts.forEach((receipt: any) => {
    if (receipt instanceof File) {
      files.push(receipt);
    } else {
      newReceipts.push(receipt as TEncodedFileAttachment);
    }
  });
  const allReceipts = newReceipts.concat(await getEncodedAttachments(files));
  return allReceipts;
};

/**
 * @public
 */

export const modelExpenseClaimFormItemDataState = async (expenseClaimItem: TClaimItem) => {
  const state: TClaimItemFormData = {
    id: expenseClaimItem.id,
    memo: expenseClaimItem.memo,
    expense_date: expenseClaimItem.expense_date || '',
    category: {
      label: expenseClaimItem.category.name,
      value: expenseClaimItem.category.internal_id
    },
    currency: {
      label: expenseClaimItem.currency.iso_code,
      value: expenseClaimItem.currency.iso_code
    },
    amount: convertFloatToStringFloatWithCommas(expenseClaimItem.amount),
    exchange_rate: convertFloatToStringFloatWithCommas(expenseClaimItem.exchange_rate),
    receipts: expenseClaimItem.receipts || ([] as TClaimItemReceipt[])
  };
  return state;
};

/**
 * @public
 */
export const modelNewExpenseClaimFormDataState = async (expenseClaim: TClaim) => {
  const state: TClaimFormData = {
    id: expenseClaim.id,
    purpose: expenseClaim.purpose,
    advance: convertFloatToStringFloatWithCommas(expenseClaim.advance),
    pay_currency: {
      label: expenseClaim.pay_currency.name,
      value: expenseClaim.pay_currency.iso_code
    },
    expense_date: expenseClaim.expense_date,
    expense_items: await Promise.all(
      expenseClaim.expense_items.map(async (item) => {
        return {
          id: item.id,
          memo: item.memo,
          expense_date: item.expense_date || '',
          category: {
            label: item.category.name,
            value: item.category.internal_id
          },
          currency: {
            label: item.currency.iso_code,
            value: item.currency.iso_code
          },
          amount: convertFloatToStringFloatWithCommas(item.amount),
          exchange_rate: convertFloatToStringFloatWithCommas(item.exchange_rate),
          receipts: item.receipts || ([] as TClaimItemReceipt[])
        };
      }, [])
    )
  };
  return state;
};

/**
 * @public
 */

export const modelExpenseClaimPayload = async (
  expenseClaim: TClaimFormData,
  user?: TAuthUser,
  status?: string
) => {
  if (!status) {
    status = 'DRAFT';
  }
  const payload: TClaimPayload = {
    status,
    advance: convertFloatStringToFloatWithDecimals(expenseClaim.advance),
    purpose: expenseClaim.purpose,
    user_id: user?.id,
    supervisor_id: user?.reports_to?.id,
    total: await Promise.all(
      expenseClaim.expense_items.map((item) => {
        const amount = convertFloatStringToFloatWithDecimals(item.amount);
        const exchange_rate = convertFloatStringToFloatWithDecimals(item.exchange_rate);
        // todo: fix this
        return Number((amount * exchange_rate).toFixed(2));
      })
    ).then((amounts) => {
      return amounts.reduce((a, b) => a + b, 0);
    }),
    expense_date: expenseClaim.expense_date,
    pay_currency_iso_code: `${expenseClaim.pay_currency.value}`,
    advance_currency_iso_code: `${expenseClaim.pay_currency.value}`,
    expense_items: await Promise.all(
      expenseClaim.expense_items.map(async (item) => {
        return {
          memo: item.memo,
          expense_date: item.expense_date,
          exchange_rate: convertFloatStringToFloatWithDecimals(item.exchange_rate),
          category_internal_id: +item.category.value,
          currency_iso_code: `${item.currency.value}`,
          amount: convertFloatStringToFloatWithDecimals(item.amount),
          receipts: await modelReceipts(item.receipts)
        };
      }, [])
    )
  };
  return payload;
};

/**
 * @public
 */

export const modelNewExpenseClaimItemPayload = async (
  expenseClaimId: string,
  expenseClaimItem: TClaimItemFormData
) => {
  console.log('modelNewExpenseClaimItemPayload', { expenseClaimItem });
  const claim_item = {
    expense_id: expenseClaimId,
    memo: expenseClaimItem.memo,
    expense_date: expenseClaimItem.expense_date,
    exchange_rate: convertFloatStringToFloatWithDecimals(expenseClaimItem.exchange_rate),
    category_internal_id: +expenseClaimItem.category.value,
    currency_iso_code: `${expenseClaimItem.currency.value}`,
    amount: convertFloatStringToFloatWithDecimals(expenseClaimItem.amount),
    receipts: await Promise.all(await modelReceipts(expenseClaimItem.receipts))
  };
  return claim_item;
};

export const updateExpenseClaimFormDataState = (
  prevClaim: TClaimFormData,
  newClaimItems: TClaimItemFormData[],
  index?: number
): TClaimFormData => {
  if (index !== undefined) {
    const updatedClaimItems = [...prevClaim.expense_items];
    updatedClaimItems[index] = newClaimItems[0];
    return { ...prevClaim, expense_items: updatedClaimItems };
  }

  // Create a map for new claim items that have an `id` (i.e., existing items)
  const newItemsWithIdMap = new Map(
    newClaimItems
      .filter((item) => item.id) // Only include items with an `id`
      .map((item) => [item.id, item])
  );

  // Separate out the new items without an `id` (likely new unsaved items)
  const newItemsWithoutId = newClaimItems.filter((item) => !item.id);

  let updatedClaimItems: TClaimItemFormData[] = [];

  if (prevClaim.expense_items !== undefined && prevClaim.expense_items !== null) {
    // Update existing items and retain those not in the new list
    updatedClaimItems = prevClaim.expense_items.map((item) => {
      // Check if there's a matching new item with the same `id`
      const matchingNewItem = newItemsWithIdMap.get(item.id);
      if (matchingNewItem) {
        // Remove the matched item from the map to avoid adding it again
        newItemsWithIdMap.delete(item.id);
        // Return the updated item
        return matchingNewItem;
      }
      // Return the existing item if no match is found
      return item;
    });
  }

  // Combine:
  // 1. The updated existing items (with or without `id`s)
  // 2. Any new items with an `id` that weren’t matched (remaining in the map)
  // 3. New items that don't have an `id` (new items to be created in the database)
  const allClaimItems = [
    ...updatedClaimItems, // Updated existing items
    ...newItemsWithIdMap.values(), // New unmatched items with `id`
    ...newItemsWithoutId // New items without `id`
  ];

  return { ...prevClaim, expense_items: allClaimItems };
};
