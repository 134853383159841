import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { RouterProvider } from 'react-router-dom';

import { ChakraProvider } from '@chakra-ui/react';

import { ThemeProvider } from '@mui/material';

import { AuthProvider } from '@people/ui/auth';
import {
  GlobalStateProvider,
  LoadingResource,
  MUI_THEME_IDENTIFIER,
  ReactQueryProvider,
  chakraTheme,
  getMuiTheme,
  routing
} from '@people/ui/shared';

import reportWebVitals from './reportWebVitals';
import './styles/index.scss';

const pdfRegex = /^\/pdf\/.*/;

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <ChakraProvider theme={{ ...chakraTheme, [MUI_THEME_IDENTIFIER]: getMuiTheme('light') }}>
        <ThemeProvider theme={getMuiTheme('light')}>
          <React.Suspense fallback={<LoadingResource />}>
            <ReactQueryProvider>
              <GlobalStateProvider>
                <AuthProvider>
                  {pdfRegex.test(window.location.pathname) ? (
                    <React.Fragment />
                  ) : (
                    <RouterProvider router={routing} />
                  )}
                </AuthProvider>
              </GlobalStateProvider>
            </ReactQueryProvider>
          </React.Suspense>
        </ThemeProvider>
      </ChakraProvider>
    </HelmetProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
