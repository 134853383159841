import { Form, Formik } from 'formik';
import React from 'react';
import * as yup from 'yup';

import { Button, Flex } from '@chakra-ui/react';

import { TClaimItemFormData, TCurrencyExchangeRate } from '@people/ui/claims/interfaces';
import {
  FormDate,
  FormNumber,
  FormSelect,
  FormTextArea,
  FormTextNumber,
  TOnFormSubmit,
  TOnFormSubmitRef
} from '@people/ui/shared';

import { useCategorySelectOptions, useCurrencySelectOptions } from '../hooks';

type TExpenseITemFormProps<T> = {
  initialValues: T;
  netSuiteExchangeRate?: TCurrencyExchangeRate;
  validationSchema: yup.ObjectSchema<any, any, any, any>;
  handleExpenseClaimItemFormSubmit: TOnFormSubmit<T>;
  expenseClaimItemFormBtnSubmitRef?: TOnFormSubmitRef;
  handleChangeExchangeRatePayload?: (data: {
    date?: string;
    source?: string;
    target?: string;
  }) => void;
};

export const ExpenseItemForm: React.FC<TExpenseITemFormProps<TClaimItemFormData>> = ({
  initialValues,
  validationSchema,
  handleExpenseClaimItemFormSubmit,
  expenseClaimItemFormBtnSubmitRef,
  handleChangeExchangeRatePayload,
  netSuiteExchangeRate
}) => {
  const { currencyOptions } = useCurrencySelectOptions();
  const { claimCategoryOptions } = useCategorySelectOptions();

  return (
    <Formik
      enableReinitialize={false}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleExpenseClaimItemFormSubmit}>
      {(props) => (
        <Flex as={Form} flexGrow={1} flexDirection="column" gap={2}>
          <Flex flexDirection="row" flexGrow={1} gap={4}>
            <Flex gap={4} flex={1} flexDirection="column">
              <FormDate
                name="expense_date"
                label="Expense Date"
                placeholder="Expense Date"
                onChange={(value) => handleChangeExchangeRatePayload?.({ date: value })}
              />
              <FormSelect
                name="category"
                label="Category"
                placeholder="Expense Category"
                selectOptions={claimCategoryOptions}
              />
            </Flex>
            <Flex gap={4} flex={1} flexDirection="column">
              <FormSelect
                name="currency"
                label="Currency"
                selectOptions={currencyOptions}
                onChange={(value) =>
                  handleChangeExchangeRatePayload?.({ source: `${value.value}` })
                }
                placeholder="Expense Currency"
              />
              <FormNumber
                placeholder="NetSuite Exchange Rate"
                label="NetSuite Exchange Rate"
                name="netsuite_exchange_rate"
                disabled={true}
                value={netSuiteExchangeRate?.exchange_rate}
              />
            </Flex>
            <Flex gap={4} flex={1} flexDirection="column">
              <FormTextNumber name="amount" label="Amount" placeholder="Expense Amount" />
              <FormTextNumber
                name="exchange_rate"
                label="Custom Exchange Rate"
                placeholder="Custom Exchange Rate"
              />
            </Flex>
          </Flex>

          <Flex flexDirection="column" flexGrow={1} gap={4}>
            <Flex gap={4} flex={1} flexDirection="column">
              <FormTextArea name="memo" label="Memo" placeholder="Expense Memo" />
            </Flex>
          </Flex>

          <Button
            size="sm"
            type="submit"
            display="none"
            colorScheme="primary"
            isLoading={props.isSubmitting}
            ref={expenseClaimItemFormBtnSubmitRef}>
            Submit
          </Button>
        </Flex>
      )}
    </Formik>
  );
};
