import {
  type FileObject,
  FileType,
  FileViewer,
  getFileFromBase64
} from '@wiocc-systems/wiocc-react-utils';
import '@wiocc-systems/wiocc-react-utils/dist/style.css';
import { useEffect, useState } from 'react';

import { Flex } from '@chakra-ui/react';

import { getFullBase64Str } from '@people/ui/shared';
import { TEncodedFileAttachment } from '@people/ui/shared/interfaces';

export type TFilePreviewProps = {
  isPDF?: boolean;
  inSingleView?: boolean;
  file: FileObject | string | TEncodedFileAttachment;
};

export const FilePreview = ({ file: mFile, inSingleView }: TFilePreviewProps) => {
  const [file, setFile] = useState<File | undefined>();

  useEffect(() => {
    if (mFile === null || mFile === undefined) {
      return;
    }

    const mFileIsStr = typeof mFile === 'string';

    if (mFile instanceof File) {
      setFile(mFile);
      return;
    }

    const base64 = mFileIsStr ? mFile : getFullBase64Str(mFile);

    // const base64 = getFullBase64Str(mFile);
    console.log({ mFile, base64 });

    getFileFromBase64(base64)
      .then((blob) => {
        // todo: see about name here
        const name = mFileIsStr ? 'File' : mFile.name;
        setFile(new File([blob], name, { type: blob.type }));
      })
      .catch((e) => {
        console.log('error', e);
      });
  }, [mFile]);

  if (file === null || file === undefined || (typeof file === 'string' && file === '')) {
    return <div>Loading...</div>;
  }

  return (
    <Flex
      flexDirection="column"
      gap={2}
      flexGrow={1}
      minH={inSingleView === true ? 'auto' : 320}
      overflow="auto"
      position="relative">
      <Flex
        p={1}
        bg="white"
        flexShrink={0}
        fontSize="sm"
        borderRadius="sm"
        justifyContent="center"
        alignItems="center"
        mb={1}
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 1,
          background: 'white'
        }}>
        {file.name}
      </Flex>
      <FileViewer
        fileType={file.type}
        filePath={file}
        onError={() => {
          console.log('🔥 ERRRORRRRRRR');
        }}
      />
    </Flex>
  );
};

export { FileType };
