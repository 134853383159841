import { format } from 'date-fns';
import { type MRT_ColumnDef } from 'material-react-table';
import React from 'react';
import { NavLink } from 'react-router-dom';

import { Box } from '@chakra-ui/react';

import { TClaim } from '@people/ui/claims/interfaces';
import { convertFloatToStringFloatWithCommas } from '@people/ui/shared';

type TUseExpenseSummaryColumnsReturn = {
  columns: MRT_ColumnDef<TClaim>[];
};

/**
 * @public
 */

export const useExpenseSummaryColumns = (): TUseExpenseSummaryColumnsReturn => {
  const columns = React.useMemo<MRT_ColumnDef<TClaim>[]>(
    () => [
      {
        id: 'view',
        header: 'View',
        accessorFn: (row) => row.id,
        size: 50,
        Cell: ({ renderedCellValue }) => (
          <Box
            as={NavLink}
            fontWeight="semibold"
            color="#1F1A6C"
            to={`${renderedCellValue}`}
            _hover={{
              color: '#4C4E9A',
              textDecoration: 'underline'
            }}>
            View
          </Box>
        )
      },
      {
        accessorKey: 'er_number',
        header: 'ER No.',
        Cell: ({ cell }) => (cell.getValue<string>() ? cell.getValue<string>() : '--')
      },
      {
        accessorKey: 'expense_date',
        header: 'Expense Date',
        Cell: ({ cell }) => format(new Date(cell.getValue<string>()), 'dd/MM/yyyy')
      },
      { accessorKey: 'purpose', header: 'Purpose', Cell: ({ cell }) => cell.getValue<string>() },
      {
        accessorKey: 'expense_items',
        header: 'No. of Claims',
        Cell: ({ cell }) => (cell.getValue<[]>() ? cell.getValue<[]>().length : 0)
      },
      {
        accessorKey: 'total',
        header: 'Total',
        accessorFn: (row) =>
          `${row.pay_currency?.iso_code ?? ''} ${convertFloatToStringFloatWithCommas(row.total)}`,
        Cell: ({ renderedCellValue }) => renderedCellValue
      },
      { accessorKey: 'status', header: 'Status', Cell: ({ cell }) => cell.getValue<string>() }
    ],
    []
  );
  return { columns };
};
