import { format } from 'date-fns';
import { Form, Formik } from 'formik';
import React from 'react';
import * as yup from 'yup';

import { Button, Flex } from '@chakra-ui/react';

import { TClaimFormData, TClaimItemFormData } from '@people/ui/claims/interfaces';
import {
  FormDate,
  FormSelect,
  FormTextArea,
  FormTextNumber,
  TOnFormSubmit,
  TOnFormSubmitRef
} from '@people/ui/shared';

import { useUserPayCurrencies } from '../hooks';

type TExpenseFormProps<T> = {
  handleExpenseClaimFormSubmit: TOnFormSubmit<T>;
  expenseClaimSubmitBtnRef?: TOnFormSubmitRef;
  handleChangeExchangeRatePayload?: (data: {
    date?: string;
    source?: string;
    target?: string;
  }) => void;
};

export const ExpenseForm: React.FC<TExpenseFormProps<TClaimFormData>> = ({
  handleExpenseClaimFormSubmit,
  expenseClaimSubmitBtnRef,
  handleChangeExchangeRatePayload
}) => {
  const { userPayCurrenciesOptions } = useUserPayCurrencies();

  const initialValues: TClaimFormData = React.useMemo(() => {
    return {
      expense_date: format(new Date(), 'yyyy-MM-dd'),
      pay_currency: userPayCurrenciesOptions[0],
      purpose: '',
      advance: '0',
      expense_items: [] as TClaimItemFormData[]
    };
  }, [userPayCurrenciesOptions]);

  const validationSchema = yup.object().shape({
    expense_date: yup.date().required('Expense date is required'),
    pay_currency: yup.object().shape({
      value: yup.string().required('Pay currency is required')
    }),
    purpose: yup.string().required('A purpose for claim is required'),
    advance: yup.string().optional()
  });

  return (
    <Formik
      enableReinitialize={false}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        console.log('ExpenseForm Values', values);
        handleExpenseClaimFormSubmit(values, actions);
      }}>
      {(props) => (
        <Flex as={Form} flexGrow={1}>
          <Flex flexDirection="row" flexGrow={1} gap={4}>
            <Flex gap={1} flex={1} flexDirection="column">
              <FormDate name="expense_date" label="Date" placeholder="Date" />
              <FormSelect
                name="pay_currency"
                label="Pay Currency"
                selectOptions={userPayCurrenciesOptions}
                onChange={(value) =>
                  handleChangeExchangeRatePayload?.({ source: `${value.value}` })
                }
                placeholder="Pay Currency"
              />
              <FormTextNumber
                name="advance"
                label="Advance To Apply"
                placeholder="Advance To Apply"
              />
            </Flex>

            <Flex gap={1} flex={1} flexDirection="column">
              <FormTextArea name="purpose" label="Purpose" placeholder="ie. Internet Expense" />
            </Flex>
          </Flex>
          <Button
            size="sm"
            display="none"
            type="submit"
            colorScheme="primary"
            ref={expenseClaimSubmitBtnRef}
            isLoading={props.isSubmitting}>
            Submit
          </Button>
        </Flex>
      )}
    </Formik>
  );
};
