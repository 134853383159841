import React from 'react';
import { BiMoneyWithdraw } from 'react-icons/bi';

import { Icon } from '@chakra-ui/react';

import { SidebarLayout } from '@people/ui/shared';

import { SidebarNav } from './SidebarNav';

type TLayoutProps = {
  pageHeadTitle: string;
  children: React.ReactNode;
  pageHeadIcon: React.ReactNode;
  pageHeadActionsArea?: React.ReactNode;
};

export const Layout: React.FC<TLayoutProps> = ({
  children,
  pageHeadIcon,
  pageHeadTitle,
  pageHeadActionsArea
}) => {
  return (
    <SidebarLayout
      moduleTitle="Claims"
      pageHeadIcon={pageHeadIcon}
      pageHeadTitle={pageHeadTitle}
      sidebarNavigation={<SidebarNav />}
      pageHeadActionsArea={pageHeadActionsArea}
      moduleIcon={<Icon as={BiMoneyWithdraw} w={6} h={6} color="inherit" />}>
      {children}
    </SidebarLayout>
  );
};
