import { format } from 'date-fns';
import React from 'react';
import { HiOutlineDocumentAdd, HiOutlineDocumentRemove } from 'react-icons/hi';
import { RiCloseLargeLine } from 'react-icons/ri';
import * as yup from 'yup';

import {
  Button,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast
} from '@chakra-ui/react';

import { useAuth } from '@people/ui/auth';
import {
  FileDropZone,
  TOnFormSubmit,
  TOnFormSubmitRef,
  TSelectOption,
  convertFloatToStringFloatWithCommas,
  isEmptyString
} from '@people/ui/shared';

import { useCategorySelectOptions, useCurrencySelectOptions, useUserPayCurrencies } from '../hooks';
import {
  TClaimFormData,
  TClaimItemFormData,
  TClaimItemReceipt,
  TCurrencyExchangeRate
} from '../interfaces';
import { modelFileObjectReceipts, updateExpenseClaimFormDataState } from '../utils';
import { ExpenseItemForm } from './ExpenseItemForm';

type TExpenseItemModalFormProps<T> = {
  title?: string;
  isOpen: boolean;
  onClose: () => void;
  expenseClaimItem?: T;
  expenseClaim: TClaimFormData;
  expenseClaimItemFormBtnSubmitRef: TOnFormSubmitRef;
  handleCreateNewExpenseClaim: (expenseClaim: TClaimFormData) => void;
  handleSaveExpenseClaimItem: (expenseClaimItem: T, isUpdate?: boolean) => void;
  expenseClaimItemIsInEditMode: boolean;
  setExpenseClaim: React.Dispatch<React.SetStateAction<TClaimFormData>>;
  netSuiteExchangeRate?: TCurrencyExchangeRate;
  handleChangeExchangeRatePayload?: (data: {
    date?: string;
    source?: string;
    target?: string;
  }) => void;
};

export const ExpenseItemModalForm: React.FC<TExpenseItemModalFormProps<TClaimItemFormData>> = ({
  title,
  isOpen,
  onClose,
  expenseClaimItemFormBtnSubmitRef,
  handleCreateNewExpenseClaim,
  handleSaveExpenseClaimItem,
  setExpenseClaim,
  expenseClaimItemIsInEditMode,
  expenseClaimItem,
  expenseClaim,
  netSuiteExchangeRate,
  handleChangeExchangeRatePayload
}) => {
  const toast = useToast({ isClosable: true, position: 'top-right' });

  const { user } = useAuth();
  const { currencyOptions } = useCurrencySelectOptions();
  const { userPayCurrenciesOptions } = useUserPayCurrencies();
  const { claimCategoryOptions } = useCategorySelectOptions();

  const [expenseItemFiles, setExpenseItemFiles] = React.useState<File[]>([] as File[]);

  console.log({ expenseClaimItemIsInEditMode });

  React.useEffect(() => {
    const convertReceiptsToFiles = async (): Promise<void> => {
      if (!expenseClaimItem?.receipts?.length || !expenseClaimItemIsInEditMode) return; // Early exit if no receipts or not in edit mode

      const firstReceipt = expenseClaimItem.receipts[0];

      if (firstReceipt instanceof File) return; // If receipts are already Files, do nothing

      const convertedFiles = await modelFileObjectReceipts(
        expenseClaimItem.receipts as TClaimItemReceipt[]
      );
      setExpenseItemFiles(convertedFiles);
    };

    void convertReceiptsToFiles();
  }, [expenseClaimItem?.receipts, expenseClaimItemIsInEditMode]);

  const defaultUserCurrencyOption: TSelectOption = React.useMemo(() => {
    if (user === undefined || user?.pay_currencies === null) {
      return currencyOptions[0];
    }
    return userPayCurrenciesOptions[0];
  }, [currencyOptions, user, userPayCurrenciesOptions]);

  const initialValues: TClaimItemFormData = React.useMemo(() => {
    return {
      memo:
        expenseClaimItemIsInEditMode && expenseClaimItem !== undefined ? expenseClaimItem.memo : '',
      amount:
        expenseClaimItemIsInEditMode && expenseClaimItem !== undefined
          ? expenseClaimItem.amount
          : '0',
      exchange_rate:
        expenseClaimItemIsInEditMode && expenseClaimItem !== undefined
          ? +expenseClaimItem.exchange_rate
          : '0',
      category:
        expenseClaimItemIsInEditMode && expenseClaimItem !== undefined
          ? expenseClaimItem.category
          : (claimCategoryOptions[0] as TSelectOption),
      currency:
        expenseClaimItemIsInEditMode && expenseClaimItem !== undefined
          ? expenseClaimItem.currency
          : (defaultUserCurrencyOption as TSelectOption),
      receipts:
        expenseClaimItemIsInEditMode && expenseClaimItem !== undefined
          ? expenseClaimItem.receipts
          : ([] as TClaimItemReceipt[]),
      expense_date:
        expenseClaimItemIsInEditMode && expenseClaimItem !== undefined
          ? expenseClaimItem.expense_date
          : format(new Date(), 'yyyy-MM-dd')
    } as TClaimItemFormData;
  }, [
    defaultUserCurrencyOption,
    claimCategoryOptions,
    expenseClaimItemIsInEditMode,
    expenseClaimItem
  ]);

  const validationSchema = yup.object().shape({
    expense_date: yup.date().required('Expense date is required'),
    category: yup.object().shape({
      value: yup.string().required('Expense category is required')
    }),
    exchange_rate: yup.string().optional(),
    currency: yup.object().shape({
      value: yup.string().required('Expense currency is required')
    }),
    memo: yup.string().required('A Memo for this expense is required'),
    amount: yup.string().required('An expense amount is required')
  });

  const handleExpenseClaimItemFormSubmit: TOnFormSubmit<TClaimItemFormData> = (values, actions) => {
    console.log({ values });

    if (expenseItemFiles.length === 0) {
      toast({
        title: 'At least one receipt file is required',
        status: 'error'
      });
      actions.setSubmitting(false);
      return;
    }

    if (+values.amount === 0) {
      toast({
        title: 'Amount cannot be zero',
        status: 'error'
      });
      actions.setSubmitting(false);
      return;
    }

    if (
      (+values.exchange_rate === 0 || values.exchange_rate === '') &&
      netSuiteExchangeRate?.exchange_rate
    ) {
      values.exchange_rate = convertFloatToStringFloatWithCommas(
        netSuiteExchangeRate?.exchange_rate
      );
    }

    // Check if the entered exchange rate is more than 10% different from the NetSuite rate
    if (
      netSuiteExchangeRate?.exchange_rate &&
      +values.exchange_rate > 0 &&
      Math.abs(
        (+values.exchange_rate - netSuiteExchangeRate.exchange_rate) /
          netSuiteExchangeRate.exchange_rate
      ) > 0.1
    ) {
      toast({
        title: 'Exchange rate exceeds 10% of the NetSuite exchange rate',
        status: 'warning',
        isClosable: true
      });
    }

    values.receipts = expenseItemFiles;
    const expenseClaimHasNoId = isEmptyString(expenseClaim.id);

    if (expenseClaimHasNoId) {
      void (async (): Promise<void> => {
        const newExpenseClaim = updateExpenseClaimFormDataState(expenseClaim, [values]);
        setExpenseClaim((prev) => updateExpenseClaimFormDataState(prev!, [values]));
        await handleCreateNewExpenseClaim(newExpenseClaim);
      })();
    } else {
      void (async (): Promise<void> => {
        await handleSaveExpenseClaimItem(values, expenseClaimItemIsInEditMode);
      })();
    }
    actions.setSubmitting(false);
    setExpenseItemFiles([] as File[]);
    actions.resetForm();
    onClose();
  };

  return (
    <Modal
      size={{ base: 'full', md: '3xl' }}
      isOpen={isOpen}
      onClose={onClose}
      isCentered={true}
      scrollBehavior="inside"
      closeOnOverlayClick={false}>
      <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px) hue-rotate(90deg)" />
      <ModalContent paddingInline={0} borderRadius="md" bg="var(--ppl-colors-chakra-body-bg)">
        <ModalHeader
          h={12}
          py={1}
          as={Flex}
          flexShrink={0}
          borderRadius="md"
          paddingInline={4}
          alignItems="center"
          borderBottomWidth={1}>
          <Flex
            w="100%"
            height={10}
            alignItems="center"
            flexDirection="row"
            position="relative"
            justifyContent="space-between">
            <Text as="h3" fontSize={{ base: 'sm', md: 'md' }} fontWeight={500}>
              {title ?? 'Modal Title'}
            </Text>
            <Flex
              as={Button}
              h={8}
              w={8}
              bg="gray.200"
              borderRadius="md"
              onClick={onClose}
              alignItems="center"
              justifyContent="center"
              _hover={{ bg: 'red.500', color: 'white' }}>
              <Icon as={RiCloseLargeLine} w={5} h={5} color="inherit" />
            </Flex>
          </Flex>
        </ModalHeader>
        <ModalBody
          as={Flex}
          flexDirection="column"
          flexGrow={1}
          overflow="auto"
          paddingInline={4}
          gap={4}
          py={2}>
          <Flex flexShrink={0} minH={30}>
            <ExpenseItemForm
              initialValues={initialValues}
              validationSchema={validationSchema}
              netSuiteExchangeRate={netSuiteExchangeRate}
              handleChangeExchangeRatePayload={handleChangeExchangeRatePayload}
              handleExpenseClaimItemFormSubmit={handleExpenseClaimItemFormSubmit}
              expenseClaimItemFormBtnSubmitRef={expenseClaimItemFormBtnSubmitRef}
            />
          </Flex>
          <Flex px={2} flexGrow={1} bg="gray.50" borderRadius="md">
            <FileDropZone files={expenseItemFiles} setFiles={setExpenseItemFiles} />
          </Flex>
        </ModalBody>
        <ModalFooter
          px={4}
          h={12}
          as={Flex}
          flexShrink={0}
          borderRadius="md"
          alignItems="center"
          borderTopWidth={1}>
          <Flex
            w="100%"
            height={10}
            alignItems="center"
            flexDirection="row"
            position="relative"
            justifyContent="space-between">
            <Flex></Flex>
            <Flex
              gap={2}
              alignItems="center"
              flexDirection="row"
              position="relative"
              justifyContent="flex-end">
              <Flex
                h={8}
                gap={1}
                as={Button}
                bg="gray.300"
                borderRadius="md"
                paddingInline={2}
                onClick={onClose}
                alignItems="center"
                justifyContent="center"
                _hover={{ bg: 'gray.400' }}>
                <Icon as={HiOutlineDocumentRemove} w={4} h={4} color="inherit" /> Cancel
              </Flex>
              <Flex
                h={8}
                gap={1}
                as={Button}
                color="white"
                borderRadius="md"
                paddingInline={2}
                alignItems="center"
                bg="rgb(132, 164, 75)"
                justifyContent="center"
                onClick={() => expenseClaimItemFormBtnSubmitRef.current?.click()}
                _hover={{ bg: 'rgb(115, 145, 62)' }}>
                <Icon as={HiOutlineDocumentAdd} w={4} h={4} color="inherit" />
                {expenseClaimItemIsInEditMode ? 'Update' : 'Add'}
              </Flex>
            </Flex>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
