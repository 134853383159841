/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';

import { useToast } from '@chakra-ui/react';

import {
  THttpResponse,
  getMutationError,
  useDeleteReactQueryMutation,
  usePatchReactQueryMutation,
  useReactQueryMutation
} from '@people/ui/api';
import { TClaimFormData, TClaimItem, TClaimItemFormData } from '@people/ui/claims/interfaces';
import { isEmptyString } from '@people/ui/shared';

import {
  modelExpenseClaimFormItemDataState,
  modelNewExpenseClaimItemPayload,
  updateExpenseClaimFormDataState
} from '../utils';

interface TUseExpenseClaimItemActionsProps<T> {
  expenseClaimItem: T | null;
  expenseClaim: TClaimFormData | null;
  expenseClaimId?: string;
  expenseClaimItemId?: string;
  isProcessingExpenseClaim: boolean;
  handleHideExpenseClaimItemsModal: () => void;
  setExpenseClaim: React.Dispatch<React.SetStateAction<TClaimFormData>>;
  setIsProcessingExpenseClaim: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedExpenseItemIndex: React.Dispatch<React.SetStateAction<number | null>>;
}

// Main hook for expense claim item actions
export const useExpenseClaimItemActions = {
  create: <T extends TClaimItemFormData>(props: TUseExpenseClaimItemActionsProps<T>) => {
    const toast = useToast({ isClosable: true, position: 'top-right' });

    // Mutations
    const createMutation =
      useReactQueryMutation<THttpResponse<TClaimItem>>('/claims/expense-items');
    const createBulkMutation = useReactQueryMutation<THttpResponse<TClaimItem[]>>(
      '/claims/expense-items/bulk'
    );
    const updateMutation = usePatchReactQueryMutation<THttpResponse<TClaimItem>>(
      `/claims/expense-items/${props.expenseClaimItemId ?? props.expenseClaimItem?.id}`
    );
    const deleteMutation = useDeleteReactQueryMutation<THttpResponse<any>>(
      `/claims/expense-items/${props.expenseClaimItemId}`
    );

    // Common mutation handler
    const handleMutation = async (expenseClaimItem: TClaimItemFormData, isUpdate?: boolean) => {
      if (!props.expenseClaimId || (isUpdate && !props.expenseClaimItemId)) {
        toastError(isUpdate ? 'updating' : 'creating');
        return;
      }

      props.setIsProcessingExpenseClaim(true);
      try {
        const payload = await modelNewExpenseClaimItemPayload(
          props.expenseClaimId,
          expenseClaimItem
        );
        const mutation = isUpdate ? updateMutation : createMutation;
        mutation.mutate(payload, {
          onSettled: async (data, err) => handleResponse(data, err, isUpdate)
        });
      } catch (error) {
        console.log('catched error', error);
        toastError(isUpdate ? 'updating' : 'creating');
        props.setIsProcessingExpenseClaim(false);
      }
    };

    // Handle bulk creation of expense claim items
    const handleCreateBulkExpenseClaimItems = async (
      expenseClaimItems: TClaimItemFormData[],
      expenseClaimId: string | undefined
    ) => {
      if (!expenseClaimId) {
        toastError('creating expense claim items');
        return;
      }

      props.setIsProcessingExpenseClaim(true);
      try {
        const payload = {
          expense_items: await Promise.all(
            expenseClaimItems.map((item) => modelNewExpenseClaimItemPayload(expenseClaimId, item))
          )
        };

        createBulkMutation.mutate(payload, {
          onSettled: async (data, err) => handleBulkResponse(data, err)
        });
      } catch (error) {
        toastError('creating expense claim items');
        props.setIsProcessingExpenseClaim(false);
      }
    };

    // Handle single item deletion
    const handleDeleteExpenseClaimItem = () => {
      if (!props.expenseClaimItemId) {
        toastError('deleting');
        return;
      }
      props.setIsProcessingExpenseClaim(true);
      deleteMutation.mutate(undefined, {
        onSettled: (data, err) => handleDeleteResponse(data, err)
      });
    };

    // Response Handlers
    const handleResponse = async (data: any, err: any, isUpdate?: boolean) => {
      const error = getMutationError(data, err as Error);
      if (isEmptyString(error) && data?.data?.id) {
        const newClaimItem = await modelExpenseClaimFormItemDataState(data.data);
        props.setExpenseClaim((prev) => updateExpenseClaimFormDataState(prev!, [newClaimItem]));
        toastSuccess(isUpdate ? 'updated' : 'created');
        props.setIsProcessingExpenseClaim(false);
        props.handleHideExpenseClaimItemsModal();
      } else {
        toastError(isUpdate ? 'updating' : 'creating');
      }
      props.setIsProcessingExpenseClaim(false);
      props.setSelectedExpenseItemIndex(null);
    };

    const handleBulkResponse = async (data: any, err: any) => {
      const error = getMutationError(data, err as Error);
      if (isEmptyString(error) && data?.data?.length) {
        const newClaimItems = await Promise.all(
          data.data.map(async (item: TClaimItem) => modelExpenseClaimFormItemDataState(item))
        );
        props.setExpenseClaim((prev) => updateExpenseClaimFormDataState(prev!, newClaimItems));
        toastSuccess('created');
        props.handleHideExpenseClaimItemsModal();
      } else {
        toastError('creating');
      }
      props.setIsProcessingExpenseClaim(false);
    };

    const handleDeleteResponse = (data: any, err: any) => {
      const error = getMutationError(data, err as Error);
      if (isEmptyString(error)) {
        props.setExpenseClaim((prev) => {
          const updatedClaimItems = prev!.expense_items.filter(
            (item) => item.id !== props.expenseClaimItemId
          );
          return { ...prev!, expense_items: updatedClaimItems };
        });
        toastSuccess('deleted');
        props.handleHideExpenseClaimItemsModal();
      } else {
        toastError('deleting');
      }
      props.setIsProcessingExpenseClaim(false);
    };

    // Toast Helpers
    const toastError = (action: string) => {
      toast({
        title: `Error ${action} expense claim item`,
        status: 'error'
      });
    };

    const toastSuccess = (action: string) => {
      toast({
        title: `Expense claim item ${action} successfully`,
        status: 'success'
      });
    };

    return {
      handleSaveExpenseClaimItem: (expenseClaimItem: TClaimItemFormData, isUpdate?: boolean) => {
        handleMutation(expenseClaimItem, isUpdate);
      },
      handleDeleteExpenseClaimItem,
      handleCreateBulkExpenseClaimItems
    };
  }
};
