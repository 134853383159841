import { AxiosError } from 'axios';
import React from 'react';

import { THttpResponse, useReactQuery } from '@people/ui/api';
import { userPermissionsError } from '@people/ui/auth/constants';
import { TAuthUserPermission, TAuthUserRole } from '@people/ui/auth/interfaces';

/**
 * @public
 */
type TUserPermissionsResponse = {
  user_id: string;
  permission_id: string;
  from_role_id: string;
  permission: TAuthUserPermission;
  from_role: TAuthUserRole;
};

/**
 * @public
 */

/**
 * @public
 */

export type TUseUserPermissionsReturn = {
  userPermissions: TAuthUserPermission[] | undefined;
  isFetchUserPermissionsError: boolean;
  fetchUserPermissionsError: Error | null;
  refetchUserPermissions: Function;
  fetchUserPermissionsStatus: 'error' | 'success' | 'pending';
  isFetchingUserPermissions: boolean;
};

/**
 * @public
 */
export const useUserPermissions = (id: string | undefined | null): TUseUserPermissionsReturn => {
  const [isFetchUserPermissionsError, setIsFetchUserPermissionsError] =
    React.useState<boolean>(false);
  const [fetchUserPermissionsError, setFetchUserPermissionsError] = React.useState<Error | null>(
    null
  );

  const { data, error, status, isLoading, refetch, isError, isRefetching, isFetching } =
    useReactQuery<THttpResponse<TUserPermissionsResponse>>({
      url: `/users/${id}/permissions`,
      options: { queryKey: ['user_permissions', `${id}`] }
    });

  const permissions: TAuthUserPermission[] | undefined = React.useMemo(() => {
    if (
      data !== undefined &&
      !(data instanceof AxiosError) &&
      !isLoading &&
      error === null &&
      !isRefetching &&
      !isError
    ) {
      if (Object.prototype.hasOwnProperty.call(data, 'data')) {
        if (Object.keys(data.data).length > 0) {
          const permissionData = data.data as TUserPermissionsResponse[];
          if (permissionData.length > 0) {
            return permissionData.map((permission) => {
              return {
                id: permission.permission.id,
                name: permission.permission.name,
                codename: permission.permission.codename,
                from_role: {
                  id: permission.from_role.id,
                  name: permission.from_role.name
                }
              };
            });
          }
        } else {
          return undefined;
        }
      } else {
        return undefined;
      }
    }
    return undefined;
  }, [data, error, isError, isLoading, isRefetching]);

  React.useEffect(() => {
    if (isError || error !== null || data instanceof Error || data instanceof AxiosError) {
      setIsFetchUserPermissionsError(true);
      const fetchUserUserError = new Error(`${userPermissionsError}`);
      setFetchUserPermissionsError(fetchUserUserError);
    }
  }, [isError, error, data]);

  return {
    userPermissions: permissions,
    fetchUserPermissionsError,
    isFetchUserPermissionsError,
    refetchUserPermissions: refetch,
    fetchUserPermissionsStatus: status,
    isFetchingUserPermissions: isFetching
  };
};
