import React from 'react';

import { useAuth } from '@people/ui/auth';
import { TCurrency, TSelectOption } from '@people/ui/shared';

type TUseUserPayCurrenciesReturn = {
  userPayCurrencies: TCurrency[];
  userPayCurrenciesOptions: TSelectOption[];
};

/**
 * Helper function to check if a currency has valid properties.
 */
const isValidCurrency = (currency: any): currency is TCurrency => {
  return currency?.name && currency?.iso_code;
};

/**
 * @public
 */

export const useUserPayCurrencies = (): TUseUserPayCurrenciesReturn => {
  const { user } = useAuth();

  const userPayCurrencies: TCurrency[] = React.useMemo(() => {
    if (!user?.pay_currencies) {
      return [];
    }

    if (Array.isArray(user.pay_currencies)) {
      return user.pay_currencies
        .filter(isValidCurrency)
        .map(({ name, iso_code }) => ({ name, iso_code }));
    }

    return [];
  }, [user]);

  const userPayCurrenciesOptions: TSelectOption[] = React.useMemo(() => {
    if (!user?.pay_currencies) {
      return [];
    }

    if (Array.isArray(user.pay_currencies)) {
      return user.pay_currencies
        .filter(isValidCurrency)
        .map(({ name, iso_code }) => ({ label: name, value: iso_code }));
    }

    return [];
  }, [user]);

  return { userPayCurrencies, userPayCurrenciesOptions };
};
