import { BsBuildingCheck, BsBuildingUp } from 'react-icons/bs';
import { CgFileAdd } from 'react-icons/cg';
import {
  MdOutlineAdminPanelSettings,
  MdOutlineApproval,
  MdOutlineSupervisorAccount
} from 'react-icons/md';
import { RiDraftLine, RiHomeSmile2Line } from 'react-icons/ri';
import { TbFileReport } from 'react-icons/tb';

import { Icon } from '@chakra-ui/react';

import { AppPath, TSidebarLink } from '@people/ui/shared';

export const userLinks: TSidebarLink[] = [
  {
    label: 'Dashboard',
    path: AppPath.CLAIMS,
    icon: <Icon as={RiHomeSmile2Line} w={5} h={5} color="inherit" />
  },
  {
    label: 'Submit Claim',
    path: AppPath.SUBMIT_CLAIM,
    icon: <Icon as={CgFileAdd} w={5} h={5} color="inherit" />
  },
  {
    label: 'Drafted Claims',
    path: AppPath.DRAFTED_CLAIMS,
    icon: <Icon as={RiDraftLine} w={5} h={5} color="inherit" />
  },
  {
    label: 'Claim Reports',
    path: AppPath.CLAIM_REPORTS,
    icon: <Icon as={TbFileReport} w={5} h={5} color="inherit" />
  }
];

export const supervisorLinks: TSidebarLink[] = [
  {
    label: 'For Approval',
    path: AppPath.SUPERVISOR_APPROVAL_CLAIMS,
    icon: <Icon as={MdOutlineApproval} w={5} h={5} color="inherit" />
  },
  {
    label: 'Approval Reports',
    path: AppPath.SUPERVISOR_CLAIM_REPORTS,
    icon: <Icon as={MdOutlineSupervisorAccount} w={5} h={5} color="inherit" />
  }
];

export const hrLinks: TSidebarLink[] = [
  {
    label: 'For Approval',
    path: AppPath.HR_APPROVAL_CLAIMS,
    icon: <Icon as={BsBuildingCheck} w={5} h={5} color="inherit" />
  },
  {
    label: 'Approval Reports',
    path: AppPath.HR_CLAIM_REPORTS,
    icon: <Icon as={BsBuildingUp} w={5} h={5} color="inherit" />
  }
];

export const adminLinks: TSidebarLink[] = [
  {
    label: 'All Reports',
    path: AppPath.ADMIN_CLAIM_REPORTS,
    icon: <Icon as={MdOutlineAdminPanelSettings} w={5} h={5} color="inherit" />
  }
];

export const CLAIM_APPROVAL_PERMISSION_CODENAME = 'claims:approve';
