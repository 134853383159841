import { TAuthState } from '@people/ui/auth/interfaces';
import { AppPath } from '@people/ui/shared';

/**
 * @public
 */

export const initAuthState: TAuthState = {
  user: undefined,
  error: undefined,
  isLoggingOut: false,
  isAuthenticated: false,
  isAuthenticating: false,
  redirect: AppPath.HOME
};

/**
 * @public
 */

export const authRequiredError = 'Request failed. Please login to continue.';

/**
 * @public
 */

export const authUrlError = 'Request failed. Could not get authorization url.';

/**
 * @public
 */

export const userLogoutError = 'Request failed. Could not log out user.';

/**
 * @public
 */

export const userUnauthorizedError = 'Request failed. User unauthorized.';

/**
 * @public
 */

export const userProfileError = 'Request failed. Could not fetch user profile.';

/**
 * @public
 */

export const userRolesError = 'Request failed. Could not fetch user roles.';

/**
 * @public
 */

export const userPermissionsError = 'Request failed. Could not fetch user permissions.';

/**
 * @public
 */

export const authProviderError =
  'AuthProvider context is undefined, please verify you are calling useAuth() as child of a <AuthProvider> component.';

/**
 * @public
 */
export const ADMIN_PERMISSION_CODENAME = 'system:admin';

/**
 * @public
 */
export const HR_PERMISSION_CODENAME = 'human:resource';
