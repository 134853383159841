import { AxiosError } from 'axios';
import React from 'react';

import { THttpResponse, useReactQuery } from '@people/ui/api';
import { TClaim } from '@people/ui/claims/interfaces';

/**
 * @public
 */

type TUseClaimDataReturn = {
  claimData: TClaim[];
  isClaimDataError: boolean;
  claimDataStatus: string;
  refetchClaimData: any;
  isClaimDataFetching: boolean;
  isLoadingClaimData: boolean;
};

/**
 * @public
 */

type TUseClaimDataProps = {
  status?: string;
  view?: string;
};

/**
 * @public
 */

export const useClaimData = (props: TUseClaimDataProps): TUseClaimDataReturn => {
  const [isHttpError, setHttpError] = React.useState<boolean>(false);

  const queryKey = ['summary_data'];
  const url = `/claims/expenses?limit=30&skip=0${props.status ? `&status=${props.status}` : ''}${props.view ? `&view=${props.view}` : ''}`;

  if (props.status) {
    queryKey.push(props.status);
  }
  if (props.view) {
    queryKey.push(props.view);
  }

  const { data, error, status, isLoading, refetch, isError, isRefetching, isFetching } =
    useReactQuery<THttpResponse<TClaim[]>>({
      url,
      options: { queryKey }
    });

  const claimData: TClaim[] | undefined = React.useMemo(() => {
    if (
      !isError &&
      !isLoading &&
      !isRefetching &&
      error === null &&
      data !== undefined &&
      !(data instanceof AxiosError)
    ) {
      if (Array.isArray(data.data) && data.data.length > 0) {
        return data.data as TClaim[];
      } else {
        return [] as TClaim[];
      }
    }
    return [] as TClaim[];
  }, [data, error, isError, isLoading, isRefetching]);

  React.useEffect(() => {
    if (isError || error !== null || data instanceof Error || data instanceof AxiosError) {
      setHttpError(true);
      const categoriesErr = new Error(`Error fetching summary data`);
      console.log('Summary data error', categoriesErr);
    }
  }, [claimData, isError, error, data]);

  return {
    claimData: claimData,
    isClaimDataError: isHttpError,
    claimDataStatus: status,
    refetchClaimData: refetch,
    isClaimDataFetching: isFetching,
    isLoadingClaimData: isLoading
  };
};
