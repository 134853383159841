import React from 'react';
import { BsSendCheck } from 'react-icons/bs';
import { FiSave } from 'react-icons/fi';

import { Flex, Icon } from '@chakra-ui/react';

import { ExpenseButton } from '@people/ui/claims/components';

type TSubmitClaimActionsProps = {
  isDisabled?: boolean;
  isLoading?: boolean;
  handleOnSubmitExpenseClaim: (status?: 'PENDING' | 'DRAFT') => void;
};

export const SubmitClaimActions: React.FC<TSubmitClaimActionsProps> = ({
  handleOnSubmitExpenseClaim,
  isDisabled,
  isLoading
}) => {
  return (
    <Flex gap={2} justifyContent="flex-end" alignItems="center">
      <ExpenseButton
        label="Save"
        bg="gray.200"
        bgHover="gray.300"
        isDisabled={isDisabled}
        isLoading={isLoading}
        onClick={() => handleOnSubmitExpenseClaim('DRAFT')}
        icon={<Icon as={FiSave} w={4} h={4} color="inherit" />}
      />
      <ExpenseButton
        color="white"
        label="Submit"
        bg="rgb(132, 164, 75)"
        bgHover="rgb(115, 145, 62)"
        isDisabled={isDisabled}
        isLoading={isLoading}
        onClick={() => handleOnSubmitExpenseClaim('PENDING')}
        icon={<Icon as={BsSendCheck} w={4} h={4} color="inherit" />}
      />
    </Flex>
  );
};
