import { AxiosError } from 'axios';
import React from 'react';

import { THttpResponse, useReactQuery } from '@people/ui/api';
import { GlobalReducerActionType } from '@people/ui/shared/enums';
import { useGlobalState } from '@people/ui/shared/hooks/useGlobalState';
import { TCurrency } from '@people/ui/shared/interfaces';

type TUseCurrenciesReturn = {
  currencies: TCurrency[] | undefined;
  isCurrenciesError: boolean;
  currenciesStatus: string;
  refetchCurrencies: any;
  isCurrenciesFetching: boolean;
  isLoadingCurrencies: boolean;
};

/**
 * @public
 */

export const useCurrencies = (): TUseCurrenciesReturn => {
  const { currencies, globalDispatch } = useGlobalState();

  const [currentCurrencies, setCurrentCurrencies] = React.useState<TCurrency[]>([] as TCurrency[]);

  React.useEffect(() => {
    if (currencies && currencies.length > 0) {
      setCurrentCurrencies(currencies);
    }
  }, [currencies]);

  const [isHttpError, setHttpError] = React.useState<boolean>(false);

  const { data, error, status, isLoading, refetch, isError, isRefetching, isFetching } =
    useReactQuery<THttpResponse<TCurrency[]>>({
      url: `/claims/currencies?limit=500&skip=0`,
      options: { queryKey: ['currencies'] }
    });

  const filterTypeCurrency = (array: TCurrency[]): TCurrency[] => {
    return array
      .filter((currency): currency is TCurrency => {
        return currency.hasOwnProperty('name') && currency.hasOwnProperty('iso_code');
      })
      .map((currency) => ({
        name: currency.name,
        iso_code: currency.iso_code
      }));
  };

  const fetchedCurrencies: TCurrency[] | undefined = React.useMemo(() => {
    if (
      !isError &&
      !isLoading &&
      !isRefetching &&
      error === null &&
      data !== undefined &&
      !(data instanceof AxiosError)
    ) {
      if (Array.isArray(data.data) && data.data.length > 0) {
        const filteredCurrencies = filterTypeCurrency(data.data as TCurrency[]);
        return filteredCurrencies;
      }
    }
    return undefined;
  }, [data, error, isError, isLoading, isRefetching]);

  React.useEffect(() => {
    if (isError || error !== null || data instanceof Error || data instanceof AxiosError) {
      setHttpError(true);
      const currenciesErr = new Error(`Error fetching currencies`);
      console.log('Currencies error', currenciesErr);
    } else {
      if (fetchedCurrencies !== undefined && fetchedCurrencies.length > 0) {
        globalDispatch({
          type: GlobalReducerActionType.SET_CURRENCIES,
          currencies: fetchedCurrencies
        });
      }
    }
  }, [fetchedCurrencies, isError, error, data, globalDispatch]);
  return {
    currencies: currentCurrencies,
    isCurrenciesError: isHttpError,
    currenciesStatus: status,
    refetchCurrencies: refetch,
    isCurrenciesFetching: isFetching,
    isLoadingCurrencies: isLoading
  };
};
