/* eslint-disable react-hooks/rules-of-hooks */
import { useNavigate } from 'react-router';

import { useToast } from '@chakra-ui/react';

import {
  THttpResponse,
  getMutationError,
  useDeleteReactQueryMutation,
  usePatchReactQueryMutation,
  useReactQueryMutation
} from '@people/ui/api';
import { useAuth } from '@people/ui/auth';
import { TClaim, TClaimApprovalLevelPayload, TClaimFormData } from '@people/ui/claims/interfaces';
import { isEmptyString } from '@people/ui/shared';

import { modelExpenseClaimPayload, modelNewExpenseClaimFormDataState } from '../utils';

interface TUseExpenseClaimActionsProps<T> {
  expenseClaim: T | null;
  expenseClaimId: string | undefined;
  expenseClaimItemId?: string | undefined;
  isProcessingExpenseClaim: boolean;
  handleHideExpenseClaimItemsModal: () => void;
  setExpenseClaim: React.Dispatch<React.SetStateAction<TClaimFormData>>;
  setIsProcessingExpenseClaim: React.Dispatch<React.SetStateAction<boolean>>;
}

export const useExpenseClaimActions = {
  create: <T extends TClaimFormData>(props: TUseExpenseClaimActionsProps<T>) => {
    const toast = useToast({ isClosable: true, position: 'top-right' });
    const navigate = useNavigate();
    const { user } = useAuth();

    const createMutation = useReactQueryMutation<THttpResponse<TClaim>>('/claims/expenses');
    const updateMutation = usePatchReactQueryMutation<THttpResponse<TClaim>>(
      `/claims/expenses/${props.expenseClaimId}`
    );
    const deleteMutation = useDeleteReactQueryMutation<THttpResponse<any>>(
      `/claims/expenses/${props.expenseClaimId}`
    );

    const handleMutation = async (
      expenseClaim: TClaimFormData,
      isUpdate: boolean,
      status?: string | undefined,
      approval?: TClaimApprovalLevelPayload
    ) => {
      if (
        !props.expenseClaim ||
        (isUpdate && !props.expenseClaimId && isEmptyString(props.expenseClaimId))
      ) {
        toast({
          title: `Error ${isUpdate ? 'updating' : 'creating'} expense claim`,
          status: 'error'
        });
        return;
      }

      props.setIsProcessingExpenseClaim(true);
      console.log('expenseClaim', expenseClaim);
      const payload = await modelExpenseClaimPayload(expenseClaim, user, status);

      if (approval) {
        payload.approval = approval;
      }

      const mutation = isUpdate ? updateMutation : createMutation;

      mutation.mutate(payload, {
        onSettled: async (data, err) => {
          const error = getMutationError(data, err as Error);
          if (isEmptyString(error) && data?.data?.id) {
            const createdClaim = data.data as TClaim;
            const newState = await modelNewExpenseClaimFormDataState(createdClaim);
            props.setExpenseClaim(newState);
            props.setIsProcessingExpenseClaim(false);
            props.setIsProcessingExpenseClaim(false);
            toast({
              title: `Expense claim ${isUpdate ? 'updated' : 'created'}`,
              status: 'success'
            });
            props.handleHideExpenseClaimItemsModal();
            if (
              createdClaim.status &&
              createdClaim.status.toUpperCase() === 'PENDING' &&
              createdClaim.id
            ) {
              navigate(`/claims/${props.expenseClaimId}`);
            }
          } else {
            toast({
              title: `Error ${isUpdate ? 'updating' : 'creating'} expense claim`,
              status: 'error'
            });
          }
          props.setIsProcessingExpenseClaim(false);
        }
      });
    };

    const handleCreateNewExpenseClaim = (expenseClaim: TClaimFormData) =>
      handleMutation(expenseClaim, false);

    const handleUpdateExpenseClaim = (
      expenseClaim: TClaimFormData,
      status: string,
      approval?: TClaimApprovalLevelPayload
    ) => handleMutation(expenseClaim, true, status, approval);

    const handleDeleteExpenseClaim = async () => {
      if (!props.expenseClaim || !props.expenseClaimId) {
        toast({
          title: 'Error deleting expense claim',
          status: 'error'
        });
        return;
      }

      props.setIsProcessingExpenseClaim(true);
      deleteMutation.mutate(null, {
        onSettled: async (data, err) => {
          const error = getMutationError(data, err as Error);
          if (isEmptyString(error)) {
            toast({
              title: 'Expense claim deleted',
              status: 'success'
            });
            navigate('/claims');
          } else {
            toast({
              title: 'Error deleting expense claim',
              status: 'error'
            });
          }
          props.setIsProcessingExpenseClaim(false);
        }
      });
    };

    return {
      handleCreateNewExpenseClaim,
      handleDeleteExpenseClaim,
      handleUpdateExpenseClaim
    };
  }
};
