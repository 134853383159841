import { format } from 'date-fns';
import { type MRT_ColumnDef } from 'material-react-table';
import React from 'react';
import { NavLink } from 'react-router-dom';

import { Box } from '@chakra-ui/react';

import { TClaim } from '@people/ui/claims/interfaces';

type TUseExpenseTableColumnsReturn = {
  columns: MRT_ColumnDef<TClaim>[];
};

/**
 * @public
 */

export const useExpenseTableColumns = (): TUseExpenseTableColumnsReturn => {
  const columns = React.useMemo<MRT_ColumnDef<TClaim>[]>(
    () => [
      {
        id: 'view',
        header: 'View',
        accessorFn: (row) => row.id,
        size: 50,
        Cell: ({ renderedCellValue }) => (
          <Box
            as={NavLink}
            fontWeight="semibold"
            color="#4C4E9A"
            to={`${renderedCellValue}`}
            _hover={{
              color: 'rgb(37 35 135 )',
              textDecoration: 'underline'
            }}>
            View
          </Box>
        )
      },
      {
        accessorKey: 'er_number',
        header: 'ER No.',
        Cell: ({ cell }) => (cell.getValue<string>() ? cell.getValue<string>() : '--')
      },
      {
        accessorKey: 'expense_date',
        header: 'Expense Date',
        Cell: ({ cell }) => format(new Date(cell.getValue<string>()), 'dd/MM/yyyy')
      },
      { accessorKey: 'purpose', header: 'Purpose', Cell: ({ cell }) => cell.getValue<string>() },
      { accessorKey: 'advance', header: 'Advance' },
      {
        accessorKey: 'expense_items',
        header: 'No. of Claims',
        Cell: ({ cell }) => (cell.getValue<[]>() ? cell.getValue<[]>().length : 0)
      },
      { accessorKey: 'total', header: 'Total', Cell: ({ cell }) => cell.getValue<number>() },
      { accessorKey: 'status', header: 'Status', Cell: ({ cell }) => cell.getValue<string>() },
      {
        accessorKey: 'submission_date',
        header: 'Submitted On',
        Cell: ({ cell }) =>
          cell.getValue<string>() ? format(new Date(cell.getValue<string>()), 'dd/MM/yyyy') : '--'
      },
      {
        accessorKey: 'supervisor_approval_date',
        header: 'Approved On',
        Cell: ({ cell }) =>
          cell.getValue<string>() ? format(new Date(cell.getValue<string>()), 'dd/MM/yyyy') : '--'
      }
    ],
    []
  );
  return { columns };
};
