import React from 'react';

import { Box, Flex, Heading } from '@chakra-ui/react';

import { useIsAuthorisedUser } from '@people/ui/auth';
import { adminLinks, hrLinks, supervisorLinks, userLinks } from '@people/ui/claims/constants';
import { SidebarNavLink, useGlobalState } from '@people/ui/shared';

import { useUserIsClaimApprover } from '../hooks';

type TSidebarNavProps = {};

export const SidebarNav: React.FC<TSidebarNavProps> = () => {
  const { sidebarOpen } = useGlobalState();

  const { isSystemAdmin, isHumanResource } = useIsAuthorisedUser();
  const { canApproveClaim } = useUserIsClaimApprover();

  return (
    <Flex flexDirection="column" flexGrow={1} gap={1}>
      {userLinks.map((link, index) => (
        <SidebarNavLink key={index} path={link.path} label={link.label} icon={link.icon} />
      ))}

      {canApproveClaim && (
        <Flex flexDirection="column">
          <Box h="1.5px" bg="gray.200" my={4} mx={2} />

          <Heading
            mb={4}
            fontWeight="semibold"
            fontSize="sm"
            px={2}
            color="gray.500"
            display={!sidebarOpen ? 'none' : 'block'}>
            Supervisor
          </Heading>

          <Flex flexDirection="column" gap={1}>
            {supervisorLinks.map((link, index) => (
              <SidebarNavLink key={index} path={link.path} label={link.label} icon={link.icon} />
            ))}
          </Flex>
        </Flex>
      )}

      {isHumanResource && (
        <Flex flexDirection="column">
          <Box h="1.5px" bg="gray.200" my={4} mx={2} />

          <Heading
            mb={4}
            fontWeight="semibold"
            fontSize="sm"
            px={2}
            color="gray.500"
            display={!sidebarOpen ? 'none' : 'block'}>
            HR
          </Heading>

          <Flex flexDirection="column" gap={1}>
            {hrLinks.map((link, index) => (
              <SidebarNavLink key={index} path={link.path} label={link.label} icon={link.icon} />
            ))}
          </Flex>
        </Flex>
      )}

      {isSystemAdmin && (
        <Flex flexDirection="column">
          <Box h="1.5px" bg="gray.200" my={4} mx={2} />

          <Heading
            mb={4}
            fontWeight="semibold"
            fontSize="sm"
            px={2}
            color="gray.500"
            display={!sidebarOpen ? 'none' : 'block'}>
            Admin
          </Heading>

          <Flex flexDirection="column" gap={1}>
            {adminLinks.map((link, index) => (
              <SidebarNavLink key={index} path={link.path} label={link.label} icon={link.icon} />
            ))}
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};
