import { AxiosError } from 'axios';
import React from 'react';

import { THttpResponse, useReactQuery } from '@people/ui/api';
import { ViewUIType } from '@people/ui/claims/enums';
import { TClaim } from '@people/ui/claims/interfaces';

type TUseSummaryDataReturn = {
  summaryData: TClaim[];
  isSummaryDataError: boolean;
  summaryDataStatus: string;
  refetchSummaryData: any;
  isSummaryDataFetching: boolean;
  isLoadingSummaryData: boolean;
};

export const useSummaryData = (): TUseSummaryDataReturn => {
  const [isHttpError, setHttpError] = React.useState<boolean>(false);

  const { data, error, status, isLoading, refetch, isError, isRefetching, isFetching } =
    useReactQuery<THttpResponse<TClaim[]>>({
      url: `/claims/expenses?limit=30&skip=0&view=${ViewUIType.USER}`,
      options: { queryKey: ['summary_data'] }
    });

  const summaryData: TClaim[] | undefined = React.useMemo(() => {
    if (
      !isError &&
      !isLoading &&
      !isRefetching &&
      error === null &&
      data !== undefined &&
      !(data instanceof AxiosError)
    ) {
      if (Array.isArray(data.data) && data.data.length > 0) {
        return data.data as TClaim[];
      } else {
        return [] as TClaim[];
      }
    }
    return [] as TClaim[];
  }, [data, error, isError, isLoading, isRefetching]);

  React.useEffect(() => {
    if (isError || error !== null || data instanceof Error || data instanceof AxiosError) {
      setHttpError(true);
      const categoriesErr = new Error(`Error fetching summary data`);
      console.log('Summary data error', categoriesErr);
    }
  }, [summaryData, isError, error, data]);

  return {
    summaryData: summaryData,
    isSummaryDataError: isHttpError,
    summaryDataStatus: status,
    refetchSummaryData: refetch,
    isSummaryDataFetching: isFetching,
    isLoadingSummaryData: isLoading
  };
};
