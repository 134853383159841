/* eslint-disable react/jsx-pascal-case */
import {
  type MRT_ColumnDef,
  MRT_PaginationState,
  MRT_ToggleFullScreenButton,
  MaterialReactTable,
  useMaterialReactTable
} from 'material-react-table';
import React from 'react';

import { Box } from '@mui/material';

import { TClaim } from '@people/ui/claims/interfaces';

type TDataTableProps<T> = {
  data: T;
  isLoading?: boolean;
  columns: MRT_ColumnDef<TClaim>[];
  dataTableTitle?: React.ReactNode;
};

/**
 * @public
 */

export const ExpenseDataTable: React.FC<TDataTableProps<TClaim[]>> = ({
  data,
  columns,
  isLoading,
  dataTableTitle
}) => {
  const [pagination, setPagination] = React.useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 25
  });

  const table = useMaterialReactTable({
    data,
    columns,
    state: { isLoading, pagination },
    enableHiding: false,
    enableStickyHeader: true,
    enableColumnPinning: true,
    enableColumnActions: false,
    enableColumnFilters: false,
    enableDensityToggle: false,
    enableColumnFilterModes: false,
    paginationDisplayMode: 'pages',
    onPaginationChange: setPagination,
    //
    muiTablePaperProps: {
      sx: { display: 'flex', flexDirection: 'column', flexGrow: 1, fontWeight: 'normal' }
    },
    muiTopToolbarProps: {
      sx: { flexShrink: 0 }
    },
    muiTableContainerProps: {
      sx: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        maxHeight: '100%',
        fontWeight: 'normal !important'
      }
    },
    muiBottomToolbarProps: { sx: { flexShrink: 0 } },
    //
    muiTableHeadCellProps: {
      sx: {
        fontSize: '0.875rem',
        fontWeight: '500'
      }
    },
    muiTableBodyCellProps: {
      sx: {
        fontSize: '0.875rem',
        fontWeight: 'normal !important',
        border: 0,
        borderBottom: '1px solid rgba(224, 224, 224, 1)'
      }
    },
    muiTableFooterCellProps: {
      sx: {
        fontSize: '0.875rem'
      }
    },
    muiSearchTextFieldProps: {
      size: 'small',
      variant: 'outlined'
    },
    muiPaginationProps: {
      showLastButton: false,
      showFirstButton: false,
      rowsPerPageOptions: [25, 30, 50, 100]
    },
    initialState: {
      density: 'compact',
      showGlobalFilter: true,
      showColumnFilters: false,
      columnPinning: { left: ['view'] }
    },

    renderTopToolbar: ({ table }) => {
      return (
        <Box
          display="flex"
          gap="0.5rem"
          p="4px 1rem"
          flexShrink={0}
          justifyContent="space-between"
          alignItems="center">
          <Box display="flex" gap="0.5rem" alignItems="center">
            {dataTableTitle && dataTableTitle}
          </Box>
          <Box display="flex" gap="0.5rem" alignItems="center">
            <MRT_ToggleFullScreenButton table={table} />
          </Box>
        </Box>
      );
    }
  });
  return (
    <Box flexGrow={1} display="flex" position="relative" flexDirection="column" overflow="hidden">
      <Box
        top={0}
        left={0}
        right={0}
        bottom={0}
        display="flex"
        overflow="hidden"
        position="absolute"
        flexDirection="column">
        <Box display="flex" flexGrow={1} flexDirection="column" overflow="hidden">
          <MaterialReactTable table={table} />
        </Box>
      </Box>
    </Box>
  );
};
