import { AxiosError } from 'axios';
import React from 'react';

import { THttpResponse, useReactQuery } from '@people/ui/api';
import { TClaimCategory } from '@people/ui/claims/interfaces';
import { GlobalReducerActionType } from '@people/ui/shared/enums';
import { useGlobalState } from '@people/ui/shared/hooks/useGlobalState';

type TUseClaimCategoriesReturn = {
  claimCategories: TClaimCategory[] | undefined;
  isClaimCategoriesError: boolean;
  claimCategoriesStatus: string;
  refetchClaimCategories: any;
  isClaimCategoriesFetching: boolean;
  isLoadingClaimCategories: boolean;
};

/**
 * @public
 */

export const useClaimCategories = (): TUseClaimCategoriesReturn => {
  const { claimCategories, globalDispatch } = useGlobalState();

  const [categories, setCategories] = React.useState<TClaimCategory[]>([] as TClaimCategory[]);

  React.useEffect(() => {
    if (claimCategories && claimCategories.length > 0) {
      setCategories(claimCategories);
    }
  }, [claimCategories]);

  const [isHttpError, setHttpError] = React.useState<boolean>(false);

  const { data, error, status, isLoading, refetch, isError, isRefetching, isFetching } =
    useReactQuery<THttpResponse<TClaimCategory[]>>({
      url: `/claims/categories?limit=500&skip=0`,
      options: { queryKey: ['claim_categories'] }
    });

  const filterTypeClaimCategory = (array: TClaimCategory[]): TClaimCategory[] => {
    return array
      .filter((category): category is TClaimCategory => {
        return category.hasOwnProperty('name') && category.hasOwnProperty('internal_id');
      })
      .map((category) => ({
        name: category.name,
        internal_id: category.internal_id
      }));
  };

  const fetchedCategories: TClaimCategory[] | undefined = React.useMemo(() => {
    if (
      !isError &&
      !isLoading &&
      !isRefetching &&
      error === null &&
      data !== undefined &&
      !(data instanceof AxiosError)
    ) {
      if (Array.isArray(data.data) && data.data.length > 0) {
        const filteredClaimCategory = filterTypeClaimCategory(data.data as TClaimCategory[]);
        return filteredClaimCategory;
      }
    }
    return undefined;
  }, [data, error, isError, isLoading, isRefetching]);

  React.useEffect(() => {
    if (isError || error !== null || data instanceof Error || data instanceof AxiosError) {
      setHttpError(true);
      const categoriesErr = new Error(`Error fetching categories`);
      console.log('Categories error', categoriesErr);
    } else {
      if (fetchedCategories !== undefined && fetchedCategories.length > 0) {
        globalDispatch({
          type: GlobalReducerActionType.SET_CATEGORIES,
          claimCategories: fetchedCategories
        });
      }
    }
  }, [fetchedCategories, isError, error, data, globalDispatch]);
  return {
    claimCategories: categories,
    isClaimCategoriesError: isHttpError,
    claimCategoriesStatus: status,
    refetchClaimCategories: refetch,
    isClaimCategoriesFetching: isFetching,
    isLoadingClaimCategories: isLoading
  };
};
