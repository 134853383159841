/* eslint-disable react-hooks/rules-of-hooks */
import { useToast } from '@chakra-ui/react';

import { THttpResponse, getMutationError, usePatchReactQueryMutation } from '@people/ui/api';
import { isEmptyString } from '@people/ui/shared';

import { TClaim, TClaimApprovalPayload, TClaimStatusChangePayload } from '../interfaces';

interface TUseSingleExpenseClaimActions<T> {
  expenseClaim: T;
  refetchExpenseClaim: any;
  expenseClaimId: string | undefined;
  isProcessingExpenseClaim: boolean;
  setIsProcessingExpenseClaim: React.Dispatch<React.SetStateAction<boolean>>;
}

export const useSingleExpenseClaimActions = {
  create: <T extends TClaim>(props: TUseSingleExpenseClaimActions<T>) => {
    const toast = useToast({ isClosable: true, position: 'top-right' });

    const expenseClaimApprovalMutation = usePatchReactQueryMutation<THttpResponse<TClaim>>(
      `/claims/expenses/${props.expenseClaimId}`
    );
    const handleExpenseClaimApproval = async (claimApprovalPayload: TClaimApprovalPayload) => {
      if (
        props.expenseClaim === null ||
        props.expenseClaim === undefined ||
        props.expenseClaimId === undefined
      ) {
        toast({
          title: 'Error approving expense claim',
          status: 'error'
        });
        return;
      }
      const payload = {
        ...claimApprovalPayload
      };
      props.setIsProcessingExpenseClaim(true);
      expenseClaimApprovalMutation.mutate(payload, {
        onSettled: async (data, err) => {
          const error = getMutationError(data, err as Error);
          if (
            isEmptyString(error) &&
            data !== undefined &&
            data.data !== undefined &&
            data.data !== null &&
            data.data.id !== undefined
          ) {
            props.setIsProcessingExpenseClaim(false);
            toast({
              title: 'Expense claim approved successfully',
              status: 'success'
            });
            if (props.refetchExpenseClaim) {
              props.refetchExpenseClaim();
            }
          } else {
            toast({
              title: 'Error approving expense claim',
              status: 'error'
            });
            props.setIsProcessingExpenseClaim(false);
          }
        }
      });
    };

    const expenseClaimStatusChangeMutation = usePatchReactQueryMutation<THttpResponse<TClaim>>(
      `/claims/expenses/${props.expenseClaimId}`
    );

    const handleExpenseClaimStatusChange = async (
      claimStatusChangePayload: TClaimStatusChangePayload
    ) => {
      if (
        props.expenseClaim === null ||
        props.expenseClaim === undefined ||
        props.expenseClaimId === undefined
      ) {
        toast({
          title: 'Error updating expense claim',
          status: 'error'
        });
        return;
      }
      const payload = {
        supervisor_id: props.expenseClaim.supervisor.id,
        user_id: props.expenseClaim.user.id,
        ...claimStatusChangePayload
      };
      props.setIsProcessingExpenseClaim(true);
      expenseClaimStatusChangeMutation.mutate(payload, {
        onSettled: async (data, err) => {
          const error = getMutationError(data, err as Error);
          if (
            isEmptyString(error) &&
            data !== undefined &&
            data.data !== undefined &&
            data.data !== null &&
            data.data.id !== undefined
          ) {
            props.setIsProcessingExpenseClaim(false);
            toast({
              title: 'Expense claim updated successfully',
              status: 'success'
            });
            if (props.refetchExpenseClaim) {
              props.refetchExpenseClaim();
            }
          } else {
            toast({
              title: 'Error updating expense claim',
              status: 'error'
            });
            props.setIsProcessingExpenseClaim(false);
          }
        }
      });
    };

    return {
      handleExpenseClaimApproval,
      handleExpenseClaimStatusChange
    };
  }
};
