import { Field } from 'formik';
import React from 'react';

import { FormControl, FormErrorMessage, FormLabel, Input } from '@chakra-ui/react';

type TFormTextNumberProps = {
  name: string;
  label: string;
  disabled?: boolean;
  placeholder: string;
  onChange?: (value: string | number | unknown) => void;
};

// Helper function to format the input
const formatNumberInput = (input: string) => {
  // Remove any character that is not a number, comma, or dot
  input = input.replace(/[^0-9.,]/g, '');
  // Prevent a comma or dot as the first character
  if (input.startsWith(',') || input.startsWith('.')) {
    return;
  }
  // Allow only a single decimal point by removing extra ones
  const firstDecimalIndex = input.indexOf('.');
  if (firstDecimalIndex !== -1) {
    // Remove any additional decimal points after the first one
    input =
      input.slice(0, firstDecimalIndex + 1) + input.slice(firstDecimalIndex + 1).replace(/\./g, '');
  }
  // Split the input into integer and decimal parts
  let [integerPart, decimalPart] = input.split('.');
  // Remove incorrectly placed commas and format the integer part
  integerPart = integerPart.replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  // Reconstruct formatted value
  return decimalPart !== undefined ? `${integerPart}.${decimalPart}` : integerPart;
};

export const FormTextNumber: React.FC<TFormTextNumberProps> = ({
  disabled,
  label,
  name,
  placeholder,
  onChange
}) => {
  return (
    <Field name={name}>
      {({ field, form }: any) => (
        <FormControl
          gap={2}
          width="100%"
          flexGrow={1}
          display="flex"
          flexDirection="column"
          isInvalid={form.errors[name] && form.touched[name]}>
          <FormLabel fontWeight={600} fontSize="sm">
            {label}
          </FormLabel>
          <Input
            {...field}
            px={4}
            py={2}
            type="text"
            fontSize="sm"
            borderWidth={1}
            borderRadius="md"
            disabled={disabled}
            borderColor="gray.300"
            placeholder={placeholder}
            _invalid={{ borderColor: 'red.500' }}
            _disabled={{ bg: 'gray.100', cursor: 'not-allowed' }}
            onPaste={(e) => {
              // Get the pasted content
              const pastedData = e.clipboardData.getData('Text');
              const formattedInput = formatNumberInput(pastedData);
              if (formattedInput) {
                form.setFieldValue(name, formattedInput);
              }
              e.preventDefault();
            }}
            onChange={(e) => {
              const formattedValue = formatNumberInput(e.target.value);
              form.setFieldValue(name, formattedValue);

              if (onChange) {
                onChange(formattedValue);
              }
            }}
          />
          <FormErrorMessage color={form.errors[name] && 'red.500'}>
            {form.errors[name]}
          </FormErrorMessage>
        </FormControl>
      )}
    </Field>
  );
};
