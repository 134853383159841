import React from 'react';
import { BsSendCheck } from 'react-icons/bs';
import { GrRevert } from 'react-icons/gr';
import { HiOutlineDocumentCheck } from 'react-icons/hi2';
import { RiDraftLine } from 'react-icons/ri';
import { TiCancel } from 'react-icons/ti';

import { Flex, Icon } from '@chakra-ui/react';

import { ExpenseButton } from '@people/ui/claims/components';
import { isProductionEnviroment } from '@people/ui/shared';

import { TClaim, TClaimApprovalPayload, TClaimStatusChangePayload } from '../interfaces';

type TSingleClaimActionsProps = {
  singleClaimData: TClaim;
  isProcessingExpenseClaim: boolean;
  isEditableOrSubmittable: boolean;
  isRevertableToDraft: boolean;
  isSupervisorApprovable: boolean;
  isSupervisorLevelApproved: boolean;
  handleExpenseClaimApproval?: (claimApprovalPayload: TClaimApprovalPayload) => void;
  handleExpenseClaimStatusChange?: (claimStatusChangePayload: TClaimStatusChangePayload) => void;
};

export const SingleClaimActions: React.FC<TSingleClaimActionsProps> = ({
  singleClaimData,
  handleExpenseClaimApproval,
  handleExpenseClaimStatusChange,
  isProcessingExpenseClaim,
  isEditableOrSubmittable,
  isRevertableToDraft,
  isSupervisorApprovable,
  isSupervisorLevelApproved
}) => {
  return (
    <Flex gap={2} justifyContent="flex-end" alignItems="center">
      {Object.keys(singleClaimData).length > 0 && (
        <Flex gap={2} justifyContent="flex-end" alignItems="center">
          {isEditableOrSubmittable && (
            <>
              <ExpenseButton
                label="Edit"
                bg="gray.200"
                bgHover="gray.300"
                isLoading={isProcessingExpenseClaim}
                isDisabled={isProcessingExpenseClaim}
                icon={<Icon as={RiDraftLine} w={4} h={4} color="inherit" />}
              />
              <ExpenseButton
                color="white"
                label="Submit"
                bg="rgb(132, 164, 75)"
                bgHover="rgb(115, 145, 62)"
                isLoading={isProcessingExpenseClaim}
                isDisabled={isProcessingExpenseClaim}
                onClick={() => {
                  if (handleExpenseClaimStatusChange) {
                    handleExpenseClaimStatusChange({
                      status: 'PENDING'
                    });
                  }
                }}
                icon={<Icon as={BsSendCheck} w={4} h={4} color="inherit" />}
              />
            </>
          )}

          {singleClaimData.status?.toLowerCase() === 'pending' &&
            !isProductionEnviroment &&
            !isSupervisorLevelApproved && (
              <Flex gap={2} justifyContent="flex-end" alignItems="center">
                <ExpenseButton
                  bg="red.800"
                  color="white"
                  label="Reject"
                  bgHover="red.900"
                  isLoading={isProcessingExpenseClaim}
                  onClick={() => {
                    if (handleExpenseClaimApproval) {
                      handleExpenseClaimApproval({
                        approval: {
                          rejected: true,
                          approver: 'SUPERVISOR'
                        }
                      });
                    }
                  }}
                  icon={<Icon as={TiCancel} w={4} h={4} color="inherit" />}
                />

                <ExpenseButton
                  color="white"
                  label="Approve"
                  bg="rgb(132, 164, 75)"
                  bgHover="rgb(115, 145, 62)"
                  isLoading={isProcessingExpenseClaim}
                  onClick={() => {
                    if (handleExpenseClaimApproval) {
                      handleExpenseClaimApproval({
                        approval: {
                          approved: true,
                          approver: 'SUPERVISOR'
                        }
                      });
                    }
                  }}
                  icon={<Icon as={BsSendCheck} w={4} h={4} color="inherit" />}
                />
              </Flex>
            )}

          {isRevertableToDraft && (
            <ExpenseButton
              bg="gray.200"
              bgHover="gray.300"
              label="Revert to draft"
              isLoading={isProcessingExpenseClaim}
              isDisabled={isProcessingExpenseClaim}
              onClick={() => {
                if (handleExpenseClaimStatusChange) {
                  handleExpenseClaimStatusChange({
                    status: 'DRAFT'
                  });
                }
              }}
              icon={<Icon as={GrRevert} w={4} h={4} color="inherit" />}
            />
          )}

          {isSupervisorApprovable && isProductionEnviroment && (
            <Flex gap={2} justifyContent="flex-end" alignItems="center">
              <ExpenseButton
                bg="red.800"
                color="white"
                label="Reject"
                bgHover="red.900"
                onClick={() => {
                  if (handleExpenseClaimApproval) {
                    handleExpenseClaimApproval({
                      approval: {
                        rejected: true,
                        approver: 'SUPERVISOR'
                      }
                    });
                  }
                }}
                icon={<Icon as={TiCancel} w={4} h={4} color="inherit" />}
              />
              <ExpenseButton
                color="white"
                label="Approve"
                bg="rgb(132, 164, 75)"
                bgHover="rgb(115, 145, 62)"
                onClick={() => {
                  if (handleExpenseClaimApproval) {
                    handleExpenseClaimApproval({
                      approval: {
                        approved: true,
                        approver: 'SUPERVISOR'
                      }
                    });
                  }
                }}
                icon={<Icon as={HiOutlineDocumentCheck} w={4} h={4} color="inherit" />}
              />
            </Flex>
          )}
        </Flex>
      )}
    </Flex>
  );
};
