import { format } from 'date-fns';
import React from 'react';
import { FaFileInvoice } from 'react-icons/fa6';
import { LuFilePenLine } from 'react-icons/lu';
import { MdOutlineDelete } from 'react-icons/md';
import { RiCloseLargeLine } from 'react-icons/ri';

import {
  Box,
  Button,
  Flex,
  Icon,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure
} from '@chakra-ui/react';

import { TClaimItemFormData } from '@people/ui/claims/interfaces';
import {
  FileItemsList,
  TEncodedFileAttachment,
  convertFloatStringToFloatWithDecimals,
  convertFloatToStringFloatWithCommas
} from '@people/ui/shared';

type TExpenseClaimItemsListProps = {
  pay_currency: string;
  onClickEdit: (index: number) => void;
  onClickDelete: (index: number) => void;
  expenseClaimItems: TClaimItemFormData[];
};

export const ExpenseClaimItemsList: React.FC<TExpenseClaimItemsListProps> = ({
  expenseClaimItems,
  pay_currency,
  onClickEdit,
  onClickDelete
}) => {
  const [filesToPreview, setFilesToPreview] = React.useState<TEncodedFileAttachment[]>(
    [] as TEncodedFileAttachment[]
  );
  const filesPreviewDisclosure = useDisclosure();

  const amountAgainstExchangeRate = (amount: string, exchangeRate: string) => {
    const amountFloat = convertFloatStringToFloatWithDecimals(amount);
    const exchangeRateFloat = convertFloatStringToFloatWithDecimals(exchangeRate);
    // todo: fix this
    const total = convertFloatToStringFloatWithCommas(
      Number((amountFloat * exchangeRateFloat).toFixed(2))
    );
    return total;
  };

  const getFormattedExchangeDetails = (expenseClaimItem: TClaimItemFormData) => {
    const formattedAmount = `${expenseClaimItem.currency.value} ${expenseClaimItem.amount}`;
    const total = amountAgainstExchangeRate(
      expenseClaimItem.amount,
      expenseClaimItem.exchange_rate
    );
    return `${formattedAmount} / ${pay_currency} ${total}`;
  };

  const getFormattedExchangeRate = (expenseClaimItem: TClaimItemFormData) => {
    const exRate = expenseClaimItem.exchange_rate;
    return `Rate 1 ${expenseClaimItem.currency.value} / ${exRate} ${pay_currency}`;
  };

  return (
    <Flex flexGrow={1} flexDirection="column">
      <List display="flex" flexGrow={1} gap={2} flexDirection="column">
        {expenseClaimItems.map((expenseClaimItem, index) => (
          <ListItem
            key={index}
            px={2}
            py={3}
            minH={12}
            gap={3}
            display="flex"
            borderWidth={1}
            flexShrink={0}
            borderRadius="md"
            alignItems="center"
            justifyContent={{ base: 'center', md: 'space-between' }}
            flexDirection={{ base: 'column', md: 'row' }}>
            <Flex flex={1} gap={4} alignItems="center">
              <Flex></Flex>
              <Flex
                gap={0.5}
                flexGrow={1}
                flexDirection="column"
                alignItems="flex-start"
                justifyContent="center">
                <Text fontSize="2xs">{format(expenseClaimItem.expense_date, 'dd/MM/yyyy')}</Text>
                <Text fontSize="sm">{expenseClaimItem.category.label}</Text>
                <Text fontSize="xs">{expenseClaimItem.memo}</Text>
              </Flex>
              <Flex></Flex>
            </Flex>
            <Flex flex={1} gap={3}>
              <Flex></Flex>
              <Flex
                gap={2}
                flexGrow={1}
                flexDirection="row"
                alignItems="flex-start"
                justifyContent="flex-start">
                <Flex flexDirection="column">
                  <Box fontSize="md" color="rgb(0, 120, 113)" fontWeight="semibold">
                    {getFormattedExchangeDetails(expenseClaimItem)}
                  </Box>
                  <Box fontSize="xs">{getFormattedExchangeRate(expenseClaimItem)}</Box>
                </Flex>
              </Flex>
              <Flex></Flex>
            </Flex>
            <Flex gap={2} justifyItems="flex-end">
              <Flex></Flex>

              <Flex flexShrink={0} position="relative">
                <Flex
                  h={8}
                  width={8}
                  as={Button}
                  bg="gray.100"
                  borderWidth={1}
                  borderRadius="md"
                  alignItems="center"
                  justifyContent="center"
                  onClick={() => {
                    setFilesToPreview(expenseClaimItem.receipts as TEncodedFileAttachment[]);
                    filesPreviewDisclosure.onOpen();
                  }}
                  _hover={{ bg: 'gray.200' }}>
                  <Icon as={FaFileInvoice} w={5} h={5} color="rgb(255, 167, 39)" />
                </Flex>
                <Flex
                  position="absolute"
                  right={0}
                  top="-4px"
                  bg="rgb(0, 120, 113)"
                  h={4}
                  width={4}
                  color="white"
                  borderRadius="50%"
                  fontWeight="thin"
                  fontSize="xs"
                  alignItems="center"
                  justifyContent="center">
                  {expenseClaimItem.receipts.length}
                </Flex>
              </Flex>
              <Flex
                h={8}
                w={8}
                as={Button}
                bg="gray.100"
                onClick={() => onClickEdit(index)}
                borderRadius="md"
                alignItems="center"
                justifyContent="center"
                _hover={{ bg: 'gray.200' }}>
                <Icon as={LuFilePenLine} w={5} h={5} color="#4C4E9A" />
              </Flex>
              {expenseClaimItems.length > 1 && (
                <Flex
                  h={8}
                  w={8}
                  as={Button}
                  bg="gray.100"
                  borderRadius="md"
                  alignItems="center"
                  justifyContent="center"
                  color="red.400"
                  onClick={() => onClickDelete(index)}
                  _hover={{ bg: 'red.400', color: 'white' }}>
                  <Icon as={MdOutlineDelete} w={5} h={5} color="inherit" />
                </Flex>
              )}
              <Flex></Flex>
            </Flex>
          </ListItem>
        ))}
      </List>

      {filesPreviewDisclosure.isOpen && filesToPreview.length > 0 && (
        <Modal
          isOpen={filesPreviewDisclosure.isOpen}
          onClose={filesPreviewDisclosure.onClose}
          isCentered={true}
          size="full"
          closeOnOverlayClick={false}>
          <ModalOverlay />
          <ModalContent paddingInline={0} borderRadius="md" bg="var(--ppl-colors-chakra-body-bg)">
            <ModalHeader
              px={4}
              h={12}
              as={Flex}
              flexShrink={0}
              borderRadius="md"
              alignItems="center"
              borderBottomWidth={1}>
              <Flex
                w="100%"
                height={10}
                alignItems="center"
                flexDirection="row"
                position="relative"
                justifyContent="space-between">
                <Text as="h3" fontSize={{ base: 'sm', md: 'md' }} fontWeight={500}>
                  Expense Item Receipts
                </Text>
                <Flex
                  as={Button}
                  h={8}
                  w={8}
                  bg="gray.200"
                  borderRadius="md"
                  onClick={filesPreviewDisclosure.onClose}
                  alignItems="center"
                  justifyContent="center"
                  _hover={{ bg: 'red.500', color: 'white' }}>
                  <Icon as={RiCloseLargeLine} w={5} h={5} color="inherit" />
                </Flex>
              </Flex>
            </ModalHeader>
            <ModalBody
              as={Flex}
              flexDirection="column"
              flexGrow={1}
              overflow="auto"
              paddingInline={4}
              gap={4}
              py={2}>
              <FileItemsList files={filesToPreview} />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </Flex>
  );
};
