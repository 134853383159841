import { AxiosError } from 'axios';
import React from 'react';

import { THttpResponse, useReactQuery } from '@people/ui/api';

import { TClaim } from '../interfaces';

/**
 * @public
 */

type TUseSingleClaimDataReturn = {
  singleClaimData: TClaim;
  isSingleClaimDataError: boolean;
  singleClaimDataStatus: string;
  refetchSingleClaimData: any;
  isSingleClaimDataFetching: boolean;
  isLoadingSingleClaimData: boolean;
};

/**
 * @public
 */

type TUseSingleClaimDataProps = {
  id?: string;
};

/**
 * @public
 */

export const useSingleClaimData = (props: TUseSingleClaimDataProps): TUseSingleClaimDataReturn => {
  const queryKey = ['summary_data'];
  const url = `/claims/expenses/${props.id}`;

  if (props.id) {
    queryKey.push(props.id);
  }

  const { data, error, status, isLoading, refetch, isError, isRefetching, isFetching } =
    useReactQuery<THttpResponse<TClaim>>({
      url,
      options: { queryKey }
    });

  const [isHttpError, setHttpError] = React.useState<boolean>(false);

  const singleClaimData: TClaim | undefined = React.useMemo(() => {
    if (
      !isError &&
      !isLoading &&
      !isRefetching &&
      error === null &&
      data !== undefined &&
      !(data instanceof AxiosError)
    ) {
      if (Object.keys(data.data).length > 0 && data.data.id) {
        const dataClaimItem = data.data as TClaim;
        if (dataClaimItem.id) {
          return dataClaimItem;
        }
      } else {
        return {} as TClaim;
      }
    }
    return {} as TClaim;
  }, [data, error, isError, isLoading, isRefetching]);

  React.useEffect(() => {
    if (isError || error !== null || data instanceof Error || data instanceof AxiosError) {
      setHttpError(true);
      const categoriesErr = new Error(`Error fetching summary data`);
      console.log('Summary data error', categoriesErr);
    }
  }, [singleClaimData, isError, error, data]);

  return {
    singleClaimData,
    isSingleClaimDataError: isHttpError,
    singleClaimDataStatus: status,
    refetchSingleClaimData: refetch,
    isSingleClaimDataFetching: isFetching,
    isLoadingSingleClaimData: isLoading
  };
};
