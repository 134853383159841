import { AxiosError } from 'axios';
import React from 'react';

import { THttpResponse, useReactQuery } from '@people/ui/api';
import { TMyClaimStat } from '@people/ui/claims/interfaces';
import { GlobalReducerActionType, useGlobalState } from '@people/ui/shared';

type TUseClaimsStatsReturn = {
  myStats: TMyClaimStat | undefined;
  isMyStatsError: boolean;
  myStatsStatus: string;
  refetchMyStats: any;
  isMyStatsFetching: boolean;
  isLoadingMyStats: boolean;
};

export const useClaimsStats = (): TUseClaimsStatsReturn => {
  const { myClaimStat, globalDispatch } = useGlobalState();

  const [myStats, setMyStats] = React.useState<TMyClaimStat>({} as TMyClaimStat);

  React.useEffect(() => {
    if (myClaimStat && Object.keys(myClaimStat).length > 0) {
      setMyStats(myClaimStat);
    }
  }, [myClaimStat]);

  const [isHttpError, setHttpError] = React.useState<boolean>(false);

  const { data, error, status, isLoading, refetch, isError, isRefetching, isFetching } =
    useReactQuery<THttpResponse<TMyClaimStat>>({
      url: `/claims/stats/mine`,
      options: { queryKey: ['my_stats'] }
    });

  const fetchedStats: TMyClaimStat | undefined = React.useMemo(() => {
    if (
      !isError &&
      !isLoading &&
      !isRefetching &&
      error === null &&
      data !== undefined &&
      !(data instanceof AxiosError)
    ) {
      if (typeof data.data === 'object' && Object.keys(data.data).length > 0) {
        return data.data as TMyClaimStat;
      }
    }
    return undefined;
  }, [data, error, isError, isLoading, isRefetching]);

  React.useEffect(() => {
    if (isError || error !== null || data instanceof Error || data instanceof AxiosError) {
      setHttpError(true);
      const categoriesErr = new Error(`Error fetching your stats`);
      console.log('My stats error', categoriesErr);
    } else {
      if (fetchedStats !== undefined && Object.keys(fetchedStats).length > 0) {
        globalDispatch({
          type: GlobalReducerActionType.SET_MY_CLAIM_STAT,
          myClaimStat: fetchedStats
        });
      }
    }
  }, [fetchedStats, isError, error, data, globalDispatch]);
  return {
    myStats: myStats,
    isMyStatsError: isHttpError,
    myStatsStatus: status,
    refetchMyStats: refetch,
    isMyStatsFetching: isFetching,
    isLoadingMyStats: isLoading
  };
};
