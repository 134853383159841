import React, { useMemo, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { authProviderError, initAuthState } from '@people/ui/auth/constants';
import { AuthContext, AuthReducer, TAuthContextProps } from '@people/ui/auth/contexts';
import { AuthReducerActionType, AuthStorageKey } from '@people/ui/auth/enums';
import { TAuthReducerAction, TAuthState } from '@people/ui/auth/interfaces';
import { clearCookies } from '@people/ui/auth/utils';
import { useClaimCategories } from '@people/ui/claims';
import { AppPath, LoadingResource, useCurrencies } from '@people/ui/shared';

import { useUserPermissions } from './useUserPermissions';
import { useUserRoles } from './useUserRoles';

/**
 * @public
 */

export const useAuth = (): TAuthContextProps => {
  const context = React.useContext(AuthContext);
  if (!context || context === undefined) {
    console.warn(authProviderError);
  }
  return { ...context } as TAuthContextProps;
};

/**
 * @public
 */

type TUseAuthStateReturn = {
  authState: TAuthState;
  authDispatch: React.Dispatch<TAuthReducerAction>;
};

/**
 * @public
 */

export const useAuthStateAndDispatch = (): TUseAuthStateReturn => {
  const localState = localStorage.getItem(AuthStorageKey.AUTH_STATE);
  const presentState = JSON.parse(localState ?? JSON.stringify(initAuthState));
  const [authState, authDispatch] = React.useReducer(AuthReducer, presentState as TAuthState);

  return { authState, authDispatch };
};

/**
 * @public
 */

export type TWithAuthProps = {
  isLoginPage?: boolean;
};

/**
 * @public
 */

export function withAuth<P>(
  Component: React.ComponentType<P>,
  options: TWithAuthProps = {}
): React.ComponentType<Omit<P, keyof TAuthContextProps>> {
  const displayName = `withAuth(${Component.displayName || Component.name})`;
  const C: React.FC<Omit<P, keyof TAuthContextProps>> = (props) => {
    const auth = useAuth();

    const { userPermissions: fetchedPermissions } = useUserPermissions(auth.user?.id);
    const { userRoles: fetchedRoles } = useUserRoles(auth.user?.id);

    const navigate = useNavigate();
    const { pathname } = useLocation();

    useCurrencies();
    useClaimCategories();

    // Memoized roles and permissions
    const userPermissions = useMemo(() => fetchedPermissions, [fetchedPermissions]);
    const userRoles = useMemo(() => fetchedRoles, [fetchedRoles]);

    // Keep track of dispatched states to prevent unnecessary rerenders
    const previousRolesRef = useRef(userRoles);
    const previousPermissionsRef = useRef(userPermissions);

    const { isLoginPage } = options;
    const redirect = pathname === AppPath.LOGIN ? AppPath.HOME : pathname;

    React.useEffect(() => {
      if (auth.isLoggingOut) {
        clearCookies();
        auth.authDispatch({
          redirect,
          type: AuthReducerActionType.SIGN_OUT
        });
        navigate(AppPath.LOGIN, { replace: true });
      }

      if (auth.isAuthenticating) {
        auth.loginUser();
      }

      if (!auth.isAuthenticated && !isLoginPage) {
        auth.authDispatch({
          redirect,
          type: AuthReducerActionType.REDIRECT
        });
        auth.authDispatch({
          isAuthenticating: true,
          type: AuthReducerActionType.AUTHENTICATING
        });
        navigate(AppPath.LOGIN, { replace: true });
      }

      if (auth.isAuthenticated && isLoginPage) {
        navigate(auth.redirect ?? AppPath.HOME, { replace: true });
      }

      if (auth.isAuthenticated) {
        // Dispatch roles and permissions only if they change
        if (userRoles !== previousRolesRef.current) {
          auth.authDispatch({ userRoles, type: AuthReducerActionType.USER_ROLES });
          previousRolesRef.current = userRoles;
        }

        if (userPermissions !== previousPermissionsRef.current) {
          auth.authDispatch({ userPermissions, type: AuthReducerActionType.USER_PERMISSIONS });
          previousPermissionsRef.current = userPermissions;
        }
      }

      return;
    }, [redirect, auth, navigate, isLoginPage, userPermissions, userRoles]);

    if (auth.isLoggingOut) return <LoadingResource />;

    if (auth.isAuthenticating) return <LoadingResource />;

    if (!auth.isAuthenticated && !isLoginPage) return <LoadingResource />;

    if (!auth.isAuthenticated && isLoginPage) return <Component {...(props as P)} auth={auth} />;

    if (auth.isAuthenticated && !isLoginPage) return <Component {...(props as P)} auth={auth} />;

    return <LoadingResource />;
  };

  C.displayName = displayName;

  return C;
}
