import { AxiosError } from 'axios';
import React from 'react';

import { THttpResponse, useReactQuery } from '@people/ui/api';

import { TCurrencyExchangeRate, TCurrencyExchangeRatePayload } from '../interfaces';

type TUseNetSuiteCurrencyExchangeRateReturn = {
  netSuiteExchangeRate: TCurrencyExchangeRate | undefined;
  isNetSuiteExchangeRateError: boolean;
  netSuiteExchangeRateStatus: string;
  refetchNetSuiteExchangeRate: any;
  isNetSuiteExchangeRateFetching: boolean;
  isLoadingNetSuiteExchangeRate: boolean;
};
export const useNetSuiteCurrencyExchangeRate = ({
  date,
  source,
  target
}: TCurrencyExchangeRatePayload): TUseNetSuiteCurrencyExchangeRateReturn => {
  const [isHttpError, setHttpError] = React.useState<boolean>(false);

  const { data, error, status, isLoading, refetch, isError, isRefetching, isFetching } =
    useReactQuery<THttpResponse<TCurrencyExchangeRate>>({
      url: `/claims/exchange-rates?date=${date}&source=${source}&target=${target}`,
      options: { queryKey: ['exchange_rate', date, source, target] }
    });

  const rate: TCurrencyExchangeRate | undefined = React.useMemo(() => {
    setHttpError(false);

    if (
      !isError &&
      !isLoading &&
      !isRefetching &&
      error === null &&
      data !== undefined &&
      !(data instanceof AxiosError)
    ) {
      if (data.data && Object.keys(data.data).length > 0) {
        const rateData = data.data as TCurrencyExchangeRate;
        if (rateData.exchange_rate !== null) {
          return rateData as TCurrencyExchangeRate;
        } else {
          setHttpError(true);
        }
      } else {
        setHttpError(true);
      }
    }

    return undefined;
  }, [data, error, isError, isLoading, isRefetching]);

  React.useEffect(() => {
    if (isError || error !== null || data instanceof Error || data instanceof AxiosError) {
      setHttpError(true);
      const exchangeRateErr = new Error(`Error fetching exchange rate`);
      console.log('Exchange rate error', exchangeRateErr);
    }
  }, [rate, isError, error, data]);

  return {
    netSuiteExchangeRate: rate,
    isNetSuiteExchangeRateError: isHttpError,
    netSuiteExchangeRateStatus: status,
    refetchNetSuiteExchangeRate: refetch,
    isNetSuiteExchangeRateFetching: isFetching,
    isLoadingNetSuiteExchangeRate: isLoading
  };
};
