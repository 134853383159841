import React from 'react';

import { TSelectOption, useGlobalState } from '@people/ui/shared';

export const useCurrencySelectOptions = () => {
  const { currencies } = useGlobalState();

  const currencyOptions: TSelectOption[] = React.useMemo(() => {
    if (!currencies) {
      return [] as TSelectOption[];
    }

    return currencies.map((currency) => ({
      label: currency.name,
      value: currency.iso_code
    }));
  }, [currencies]);

  return { currencyOptions };
};
