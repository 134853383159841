import React from 'react';

import { TSelectOption, useGlobalState } from '@people/ui/shared';

export const useCategorySelectOptions = () => {
  const { claimCategories } = useGlobalState();

  const claimCategoryOptions: TSelectOption[] = React.useMemo(() => {
    if (!claimCategories) {
      return [] as TSelectOption[];
    }

    return claimCategories.map((category) => ({
      label: category.name,
      value: category.internal_id
    }));
  }, [claimCategories]);

  return { claimCategoryOptions };
};
